<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar v-if="$store.state.user.auth"/>
                        
                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <modal-signup v-if="!$store.state.user.auth"/>
            <modal-login v-if="!$store.state.user.auth"/>
            <modal-restore v-if="!$store.state.user.auth"/>
            <modal-restore-password v-if="!$store.state.user.auth"/>

            <main>
                <cards-sidebar/>

                <section class="section section--text section--about-us">
                    <h1>{{ $store.state.translation.info.policy_aml_kyc.title }}</h1>

                    <h3>{{ $store.state.translation.info.policy_aml_kyc.desc1 }}</h3>
                    <p>
                        {{ $store.state.translation.info.policy_aml_kyc.desc2 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.policy_aml_kyc.desc3 }}
                    </p>

                    <ul>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc4 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc5 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc6 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc7 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc8 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc9 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc10 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc11 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc12 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc13 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc14 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc15 }}</li>
                    </ul>

                    <h3>{{ $store.state.translation.info.policy_aml_kyc.desc16 }}</h3>
                    <p>
                        {{ $store.state.translation.info.policy_aml_kyc.desc17 }}
                    </p>

                    <ul>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc18 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc19 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc20 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc21 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc22 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc23 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc24 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc25 }}</li>
                    </ul>
                    <h3>{{ $store.state.translation.info.policy_aml_kyc.desc26 }}</h3>
                    <p>
                        {{ $store.state.translation.info.policy_aml_kyc.desc27 }}
                    </p>
                    <ul>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc28 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc29 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc30 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc31 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc32 }}</li>
                    </ul>

                    <h3>{{ $store.state.translation.info.policy_aml_kyc.desc33 }}</h3>
                    <p>
                        {{ $store.state.translation.info.policy_aml_kyc.desc34 }}
                    </p>
                    <ul>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc35 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc36 }}</li>
                        <li>
                            {{ $store.state.translation.info.policy_aml_kyc.desc37 }}
                        </li>
                    </ul>

                    <h3>{{ $store.state.translation.info.policy_aml_kyc.desc38 }}</h3>
                    <p>
                        {{ $store.state.translation.info.policy_aml_kyc.desc39 }}
                    </p>

                    <h3>{{ $store.state.translation.info.policy_aml_kyc.desc40 }}</h3>
                    <p>
                        {{ $store.state.translation.info.policy_aml_kyc.desc41 }}
                    </p>
                    <ul>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc42 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc43 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc44 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc45 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc46 }}</li>
                    </ul>

                    <h3>{{ $store.state.translation.info.policy_aml_kyc.desc47 }}</h3>
                    <p>{{ $store.state.translation.info.policy_aml_kyc.desc48 }}</p>

                    <ul>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc49 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc50 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc51 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc52 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc53 }}</li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc54 }}</li>
                        <li>
                            {{ $store.state.translation.info.policy_aml_kyc.desc55 }}
                        </li>
                        <li>{{ $store.state.translation.info.policy_aml_kyc.desc56 }}</li>
                    </ul>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.info.policy_aml_kyc.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.info.policy_aml_kyc.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.info.policy_aml_kyc.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.info.policy_aml_kyc.meta.description
                    }
                ]
            }
        }
    }
</script>