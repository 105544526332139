import Vue from "vue"

import HeaderMain from "./components/common/Header-Main"
import FooterMain from "./components/common/Footer-Main"
import NavMobile from "./components/common/Nav-Mobile"
import ProfileSidebar from "./components/common/Profile-Sidebar"
import ProfileMobile from "./components/common/Profile-Mobile"
import NavSidebar from "./components/common/Nav-Sidebar"
import CardsSidebar from "./components/common/Cards-Sidebar"
import ModalCategories from "./components/common/Modal-Categories"
import ModalLogin from "./components/common/Modal-Login"
import ModalSignup from "./components/common/Modal-Signup"
import ModalRestore from "./components/common/Modal-Restore"
import ModalRestorePassword from "./components/common/Modal-Restore-Password"
import TournamentTimer from "./components/common/Tournament-Timer"

import ProfilePersonal from "./components/common/profile/Personal"
import ProfilePassword from "./components/common/profile/Password"
import Profile2Fa from "./components/common/profile/2Fa"
import ProfileEmail from "./components/common/profile/Email"

import WalletBalance from "./components/common/wallet/Balance"
import WalletDeposit from "./components/common/wallet/Deposit"
import WalletWithdraw from "./components/common/wallet/Withdraw"
import WalletHistory from "./components/common/wallet/History"

import Preloader from "./components/common/Preloader"

Vue.component('header-main', HeaderMain)
Vue.component('footer-main', FooterMain)
Vue.component('nav-mobile', NavMobile)
Vue.component('profile-sidebar', ProfileSidebar)
Vue.component('profile-mobile', ProfileMobile)
Vue.component('nav-sidebar', NavSidebar)
Vue.component('cards-sidebar', CardsSidebar)
Vue.component('modal-categories', ModalCategories)
Vue.component('modal-login', ModalLogin)
Vue.component('modal-signup', ModalSignup)
Vue.component('modal-restore', ModalRestore)
Vue.component('modal-restore-password', ModalRestorePassword)
Vue.component('tournament-timer', TournamentTimer)

Vue.component('profile-personal', ProfilePersonal)
Vue.component('profile-password', ProfilePassword)
Vue.component('profile-2fa', Profile2Fa)
Vue.component('profile-email', ProfileEmail)

Vue.component('wallet-balance', WalletBalance)
Vue.component('wallet-deposit', WalletDeposit)
Vue.component('wallet-withdraw', WalletWithdraw)
Vue.component('wallet-history', WalletHistory)

Vue.component('preloader', Preloader)

