<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar v-if="$store.state.user.auth"/>

                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <modal-signup v-if="!$store.state.user.auth"/>
            <modal-login v-if="!$store.state.user.auth"/>
            <modal-restore v-if="!$store.state.user.auth"/>
            <modal-restore-password v-if="!$store.state.user.auth"/>
            
            <main>
                <cards-sidebar/>

                <section class="section section--text section--about-us">
                    <h1>{{ $store.state.translation.info.partners.title }}</h1>

                    <div class="accordion accordion--partners">
                        <div class="accordion__item">
                            <h2 class="accordion__item-header">
                                <button type="button" class="accordion__item-btn">
                                    {{ $store.state.translation.info.partners.question1 }}
                                </button>
                            </h2>

                            <div class="accordion__item-collapse">
                                <div class="accordion__item-body">
                                    {{ $store.state.translation.info.partners.answer1 }}
                                </div>
                            </div>
                        </div>
                        <div class="accordion__item">
                            <h2 class="accordion__item-header">
                                <button type="button" class="accordion__item-btn">
                                    {{ $store.state.translation.info.partners.question2 }}
                                </button>
                            </h2>

                            <div class="accordion__item-collapse">
                                <div class="accordion__item-body">
                                    {{ $store.state.translation.info.partners.answer2 }}
                                </div>
                            </div>
                        </div>
                        <div class="accordion__item">
                            <h2 class="accordion__item-header">
                                <button type="button" class="accordion__item-btn">
                                    {{ $store.state.translation.info.partners.question3 }}
                                </button>
                            </h2>

                            <div class="accordion__item-collapse">
                                <div class="accordion__item-body">
                                    {{ $store.state.translation.info.partners.answer3 }}
                                </div>
                            </div>
                        </div>
                        <div class="accordion__item">
                            <h2 class="accordion__item-header">
                                <button type="button" class="accordion__item-btn">
                                    {{ $store.state.translation.info.partners.question4 }}
                                </button>
                            </h2>

                            <div class="accordion__item-collapse">
                                <div class="accordion__item-body">
                                    {{ $store.state.translation.info.partners.answer4 }}
                                </div>
                            </div>
                        </div>
                        <div class="accordion__item">
                            <h2 class="accordion__item-header">
                                <button type="button" class="accordion__item-btn">
                                    {{ $store.state.translation.info.partners.question5 }}
                                </button>
                            </h2>

                            <div class="accordion__item-collapse">
                                <div class="accordion__item-body">
                                    {{ $store.state.translation.info.partners.answer5 }}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.info.partners.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.info.partners.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.info.partners.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.info.partners.meta.description
                    }
                ]
            }
        },
        mounted () {

            var elements = document.querySelectorAll('.accordion__item-btn')

            for (let el of elements) {
                el.addEventListener('click', function(e){
                    //if(e.target.classList.contains('active')) {
                        e.target.classList.toggle('active')
                        e.target.parentElement.nextSibling.classList.toggle('active')
                    //}
                })
            }
        }
    }
</script>
