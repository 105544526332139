export default {
  ApplicationNAME: 'CosmoSpin',
  ApplicationAPI: 'https://api-cosmospin.casino/api/v1',
  ApplicationGamesAPI: 'https://slotegator.api-cosmospin.casino',
  ApplicationAPIURL: 'https://api-cosmospin.casino',
  ApplicationStaticURL: 'https://static-cosmospin.casino',
  ApplicationURL: 'https://cosmospin.casino',
  RecaptchaV3: {
    token: '',
    action: 'casino'
  },
  RecaptchaV2: {
    token: ''
  },
  Timezone: 'Europe/Moscow',
  contacts: {
    email: 'support@cosmospin.casino',
    telegram: 'CosmoSpinSupport',
    phone: {
      national: '+79000000000',
      international: '+7 (900) 000-00-00'
    }
  }
}
