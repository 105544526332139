<template>
    <div style="display: contents">
        <div class="modal modal--login pfixed top__zero" id="login-2fa">
            <div class="modal__inner">
                <button type="button" class="btn modal__btn-close" @click="togglePopup('login-2fa'), togglePopup('login'), is_2fa = false">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2L12 12M22 22L12 12M12 12L22 2L2 22" stroke="#5200FF" stroke-width="4" />
                    </svg>
                </button>
                <h2 class="modal__title_small">{{ $store.state.translation.modal.two_fa.google_authenticator }}</h2>
                <div class="modal__content">
                    <form class="form form--login" v-on:submit.prevent="submitLogin">
                        <div class="form__group">
                            <input 
                                @input="errors.code = null"
                                v-model="data.code"
                                type="text" 
                                class="form__input" 
                                maxlength="6"
                                :placeholder="$store.state.translation.modal.two_fa.six_code"
                                />
                            <span class="error_input" v-if="errors.code">{{ errors.code }}</span>
                        </div>
                        <div class="form__group">
                            <button type="submit" class="btn btn--fill-rose form__btn form__btn-submit" :disabled="loader_button">
                                <preloader v-if="loader_button"/>
                                {{ $store.state.translation.modal.auth }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    	<div class="modal modal--login pfixed top__zero" id="login">
            <div class="modal__inner">
                <button type="button" class="btn modal__btn-close" @click="togglePopup('login')">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2L12 12M22 22L12 12M12 12L22 2L2 22" stroke="#5200FF" stroke-width="4" />
                    </svg>
                </button>
                <h2 class="modal__title">{{ $store.state.translation.modal.login }}</h2>
                <div class="modal__content">
                    <form class="form form--login" v-on:submit.prevent="submitLogin">
                        <div class="form__group">
                            <input 
                                @input="errors.email = null"
                                v-model="data.email"
                                type="email" 
                                class="form__input" 
                                :placeholder="$store.state.translation.modal.email"
                                />
                            <span class="error_input" v-if="errors.email">{{ errors.email }}</span>
                        </div>
                        <div class="form__group">
                            <input 
                                v-model="data.password" 
                                @input="errors.password = null"
                                type="password" 
                                class="form__input" 
                                :placeholder="$store.state.translation.modal.password"
                                />
                            <span class="error_input" v-if="errors.password">{{ errors.password }}</span>
                        </div>
                        <div class="form__group">
                            <button type="submit" class="btn btn--fill-rose form__btn form__btn-submit" :disabled="loader_button">
                                <preloader v-if="loader_button"/>
                                {{ $store.state.translation.modal.auth }}
                            </button>
                        </div>

                        <button type="button" class="btn form__btn-forget" @click="togglePopup('login'), togglePopup('restore')">{{ $store.state.translation.modal.forgot_password }}</button>

                        <button type="button" class="btn form__btn form__btn-signup" @click="togglePopup('login'), togglePopup('signup')">{{ $store.state.translation.modal.register }}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loader_button: false,
                is_2fa: false,
                data: {
                    email: null,
                    password: null,
                    code: null
                },
                errors: {
                    email: false,
                    password: false,
                    code: false
                }
            };
        },
        methods: {
            submitLogin () {
                if(!this.data.email) {
                    this.errors.email = this.$store.state.translation.axios.filed_required
                    return
                }
                if(!this.data.password) {
                    this.errors.password = this.$store.state.translation.axios.filed_required
                    return
                }

                if(this.is_2fa) {
                    if(!this.data.code) {
                        this.errors.code = this.$store.state.translation.axios.filed_required
                        return
                    }
                    if(isNaN(this.data.code)) {
                        this.errors.code = this.$store.state.translation.modal.two_fa.number_code
                        return
                    }
                    if(this.data.code.length != 6) {
                        this.errors.code = this.$store.state.translation.modal.two_fa.min_length
                        return
                    }
                }

                this.loader_button = true
                
                this.$axios.post('/auth/login', JSON.stringify(this.data))
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            
                            localStorage.setItem('token', response.token)
                            
                            this.$axios.defaults.headers.common.Authorization = 'Bearer ' + response.token

                            this.togglePopup('login')

                            this.setUser(response.user)

                            break
                        case "2fa":
                            
                            this.togglePopup('login')
                            this.togglePopup('login-2fa')
                            this.is_2fa = true
                            
                            this.setUser(response.user)

                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    this.loader_button = false
                    let response = error.response.data
                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                        if('field' in response) {
                            this.errors[response.field] = response.message
                        }
                    }
                })
            }
        }
    }
</script>