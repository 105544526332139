<template>
    <div class="mobile-nav mobile-nav--lk" v-if="$store.state.user.auth">
        <div class="mobile-nav__inner">
            <button type="button" class="mobile-nav__btn" @click="openMobileToggle">
                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 1L2 7L8 13" stroke="#FF00E5" stroke-width="2" />
                </svg>
                {{ $store.state.translation.header.menu }}
            </button>

            <cards-sidebar/>

            <nav class="nav--mobile-menu">
                <ul class="nav__list">
                    <li class="nav__item">
                        <router-link class="nav__link" :to="{name: 'Home'}">
                            {{ $store.state.translation.header.games }}
                        </router-link>
                    </li>
                    <li class="nav__item">
                        <router-link class="nav__link" :to="{name: 'Tournaments'}">
                            {{ $store.state.translation.header.tournaments }}
                        </router-link>
                    </li>
                    <li class="nav__item">
                        <template v-if="$store.state.user.auth">
                            <router-link class="nav__link" :to="{name: 'AccountVip'}">
                                {{ $store.state.translation.header.vip }}
                            </router-link>
                        </template>
                        <template v-else>
                            <router-link class="nav__link" :to="{name: 'Vip'}">
                                {{ $store.state.translation.header.vip }}
                            </router-link>
                        </template>
                    </li>
                </ul>
            </nav>
        </div>
    </div>

	<div class="mobile-nav" v-else>
        <div class="mobile-nav__inner">
            <button type="button" class="mobile-nav__btn" @click="openMobileToggle">
                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 1L2 7L8 13" stroke="#FF00E5" stroke-width="2" />
                </svg>
                {{ $store.state.translation.header.menu }}
            </button>

            <cards-sidebar/>

            <nav class="nav--mobile-menu">
                <ul class="nav__list">
                    <li class="nav__item">
                        <router-link class="nav__link" :to="{name: 'Home'}">
                            {{ $store.state.translation.header.games }}
                        </router-link>
                    </li>
                    <li class="nav__item">
                        <router-link class="nav__link" :to="{name: 'Tournaments'}">
                            {{ $store.state.translation.header.tournaments }}
                        </router-link>
                    </li>
                    <li class="nav__item">
                    	<template v-if="$store.state.user.auth">
	                        <router-link class="nav__link" :to="{name: 'AccountVip'}">
	                            {{ $store.state.translation.header.vip }}
	                        </router-link>
	                    </template>
	                    <template v-else>
	                        <router-link class="nav__link" :to="{name: 'Vip'}">
	                            {{ $store.state.translation.header.vip }}
	                        </router-link>
	                    </template>
                    </li>
                </ul>
            </nav>

            <div class="mobile-nav__btns">
                <button type="button" class="btn btn--md btn--outline btn--outline-rose modal-open-btn" @click="togglePopup('login'), openMobileToggle()">
                    {{ $store.state.translation.header.login }}
                </button>
                <button type="button" class="btn btn--md btn--rose btn--fill btn--fill-rose modal-open-btn" @click="togglePopup('signup'), openMobileToggle()">
                    {{ $store.state.translation.header.register }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            openMobileToggle () {
            	document.querySelector('.mobile-nav').classList.toggle('active')
            	document.body.classList.toggle('fixed')   	
            }
        },
        mounted () {
            var elements = document.querySelectorAll('.nav__link')

            for (let el of elements) {
                el.addEventListener('click', function(e){
                    document.body.classList.remove('fixed')   
                })
            }
        }
    }
</script>