<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar v-if="$store.state.user.auth"/>
                        
                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <modal-signup v-if="!$store.state.user.auth"/>
            <modal-login v-if="!$store.state.user.auth"/>
            <modal-restore v-if="!$store.state.user.auth"/>
            <modal-restore-password v-if="!$store.state.user.auth"/>

            <main>
                <cards-sidebar/>

                <section class="section section--text section--partners">
                    <h1>{{ $store.state.translation.info.affiliate_program.title }}</h1>

                    <p>
                        {{ $store.state.translation.info.affiliate_program.desc }}
                    </p>

                    <form class="form form--verify form--partners" v-on:submit.prevent="submitForm">
                        <div class="form__group">
                            <label for="p-input-email" class="form__label">
                                <span>{{ $store.state.translation.info.affiliate_program.email }} <span class="red">*</span></span>
                                <input 
                                    v-model="data.email"
                                    @input="errors.email = null"
                                    type="email" 
                                    class="form__input" 
                                    :placeholder="$store.state.translation.info.affiliate_program.email" />
                                <span class="error_input" v-if="errors.email">{{ errors.email }}</span>
                            </label>
                        </div>
                        <div class="form__group">
                            <label for="p-input-src-traffic" class="form__label">
                                <span>{{ $store.state.translation.info.affiliate_program.source }} <span class="red">*</span></span>
                                <input 
                                    v-model="data.traffic"
                                    @input="errors.traffic = null"
                                    type="text" 
                                    class="form__input" 
                                    :placeholder="$store.state.translation.info.affiliate_program.source" />
                                <span class="error_input" v-if="errors.traffic">{{ errors.traffic }}</span>
                            </label>
                        </div>
                        <div class="form__group">
                            <label for="p-input-tg" class="form__label">
                                <span>{{ $store.state.translation.info.affiliate_program.telegram }} <span class="red">*</span></span>
                                <input 
                                    v-model="data.telegram"
                                    @input="errors.telegram = null"
                                    type="text" 
                                    class="form__input" 
                                    :placeholder="$store.state.translation.info.affiliate_program.telegram" />
                                <span class="error_input" v-if="errors.telegram">{{ errors.telegram }}</span>
                            </label>
                        </div>
                        <div class="form__group">
                            <label for="p-input-budget" class="form__label">
                                <span>{{ $store.state.translation.info.affiliate_program.budget }} <span class="red">*</span></span>
                                <input 
                                    v-model="data.budget"
                                    @input="errors.budget = null"
                                    type="text" 
                                    class="form__input" 
                                    :placeholder="$store.state.translation.info.affiliate_program.budget" />
                                <span class="error_input" v-if="errors.budget">{{ errors.budget }}</span>
                            </label>
                        </div>
                        <div class="form__group form__group--textarea">
                            <label for="p-textarea" class="form__label">
                                <span>{{ $store.state.translation.info.affiliate_program.additional }}</span>

                                <textarea
                                    v-model="data.aditional"
                                    @input="errors.aditional = null"
                                    autocomplete="off"
                                    :placeholder="$store.state.translation.info.affiliate_program.additional"
                                    class="form__textarea"
                                ></textarea>
                            </label>
                        </div>
                        <button 
                            :disabled="loader_button"
                            type="submit" 
                            class="btn btn--fill-rose form__submit btn-base-next">
                            <preloader v-if="loader_button"/>
                            {{ $store.state.translation.info.affiliate_program.send }}
                        </button>
                    </form>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.info.affiliate_program.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.info.affiliate_program.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.info.affiliate_program.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.info.affiliate_program.meta.description
                    }
                ]
            }
        },
        data () {
            return {
                loader_button: false,

                data: {
                    email: null,
                    traffic: null,
                    telegram: null,
                    budget: null,
                    aditional: null
                },
                errors: {
                    email: false,
                    traffic: false,
                    telegram: false,
                    budget: false,
                    aditional: false
                }
            }
        },
        methods: {
            async submitForm () {

                if(!this.data.email) {
                    this.errors.email = this.$store.state.translation.axios.filed_required
                    return
                }
                if(!this.data.traffic) {
                    this.errors.traffic = this.$store.state.translation.axios.filed_required
                    return
                }
                if(!this.data.telegram) {
                    this.errors.telegram = this.$store.state.translation.axios.filed_required
                    return
                }
                if(!this.data.budget) {
                    this.errors.budget = this.$store.state.translation.axios.filed_required
                    return
                }
                if(!this.data.aditional) {
                    this.errors.aditional = this.$store.state.translation.axios.filed_required
                    return
                }

                this.loader_button = true

                this.$axios.post('/partner/request', JSON.stringify({
                    email: this.data.email,
                    traffic: this.data.traffic,
                    telegram: this.data.telegram,
                    budget: this.data.budget,
                    aditional: this.data.aditional
                }))
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":

                            this.data.email = null
                            this.data.traffic = null
                            this.data.telegram = null
                            this.data.budget = null
                            this.data.aditional = null

                            this.$toast.success(response.message)

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    this.loader_button = false
                    let response = error.response.data
                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                        if('field' in response) {
                            this.errors[response.field] = true
                        }
                    }
                })
                
            }
        },
    }
</script>

<style lang="css" scoped>
    ::v-deep .form {
        margin-bottom: 5rem!important
    }

    @media (max-width: 47.99875em){
        .form__row {
            flex-direction: column!important;
        }
    }
</style>
