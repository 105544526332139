<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar/>

                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <main>
                <cards-sidebar/>

                <section class="section section--profile">
                    
                    <profile-mobile/>

                    <h2 class="section__title">{{ $store.state.translation.profile.title }}</h2>

                    <div class="section__content">
                        <div class="tabs">
                            <ul class="tabs__nav">
                                <li class="tabs__nav-item">
                                    <button type="button" class="btn btn--fill-purple tabs__nav-btn" @click="changeTab('personal')" :class="{'active': tab == 'personal'}">
                                        {{ $store.state.translation.profile.personal_title }}
                                    </button>
                                </li>
                                <li class="tabs__nav-item">
                                    <button type="button" class="btn btn--fill-purple tabs__nav-btn" @click="changeTab('password')" :class="{'active': tab == 'password'}">
                                        {{ $store.state.translation.profile.change_pass_title }}
                                    </button>
                                </li>
                                <li class="tabs__nav-item">
                                    <button type="button" class="btn btn--fill-purple tabs__nav-btn" @click="changeTab('2fa')" :class="{'active': tab == '2fa'}">
                                        {{ $store.state.translation.profile.two_fa_title }}
                                    </button>
                                </li>
                                <li class="tabs__nav-item">
                                    <button type="button" class="btn btn--fill-purple tabs__nav-btn" @click="changeTab('email')" :class="{'active': tab == 'email'}">
                                        {{ $store.state.translation.profile.confirm_email_title }}
                                    </button>
                                </li>
                            </ul>

                            <div class="tabs__content">
                                <div class="tabs__pane" :class="{'active': tab == 'personal'}">
                                    <profile-personal v-if="tab == 'personal'"/>
                                </div>
                                <div class="tabs__pane" :class="{'active': tab == 'password'}">
                                    <profile-password v-if="tab == 'password'"/>
                                </div>
                                <div class="tabs__pane" :class="{'active': tab == '2fa'}">
                                    <profile-2fa v-if="tab == '2fa'"/>
                                </div>
                                <div class="tabs__pane" :class="{'active': tab == 'email'}">
                                    <profile-email v-if="tab == 'email'"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.profile.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.profile.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.profile.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.profile.meta.description
                    }
                ]
            }
        },
        data() {
            return {
                tab: 'personal'
            }
        },
        methods: {
            changeTab (tab) {
                this.tab = tab
            }
        }
    }
</script>
