<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar v-if="$store.state.user.auth"/>

                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <modal-signup v-if="!$store.state.user.auth"/>
            <modal-login v-if="!$store.state.user.auth"/>
            <modal-restore v-if="!$store.state.user.auth"/>
            <modal-restore-password v-if="!$store.state.user.auth"/>
            
            <main>
                <cards-sidebar/>

                <section class="section section--text section--about-us">
                    <h1>{{ $store.state.translation.info.responsible_game.title }}</h1>

                    <p>
                        {{ $store.state.translation.info.responsible_game.desc1 }}
                    </p>

                    <h3>{{ $store.state.translation.info.responsible_game.desc2 }}</h3>

                    <p>
                        {{ $store.state.translation.info.responsible_game.desc3 }}
                    </p>
                    <ul>
                        <li>{{ $store.state.translation.info.responsible_game.desc4 }}</li>
                        <li>{{ $store.state.translation.info.responsible_game.desc5 }}</li>
                        <li>{{ $store.state.translation.info.responsible_game.desc6 }}</li>
                        <li>{{ $store.state.translation.info.responsible_game.desc7 }}</li>
                        <li>{{ $store.state.translation.info.responsible_game.desc8 }}</li>
                        <li>{{ $store.state.translation.info.responsible_game.desc9 }}</li>
                    </ul>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc10 }} <a :href="`mailto:${$config.contacts.email}`">{{ $config.contacts.email }}</a>.
                    </p>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc11 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc12 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc13 }}
                    </p>
                    <p>
                        <a href="https://netnanny.com">www.netnanny.com</a>
                    </p>
                    <p>
                        <a href="https://cyberpatrol.com">www.cyberpatrol.com</a>
                    </p>

                    <h3>{{ $store.state.translation.info.responsible_game.desc14 }}</h3>

                    <p>
                        {{ $store.state.translation.info.responsible_game.desc15 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc16 }}
                    </p>

                    <ul>
                        <li>{{ $store.state.translation.info.responsible_game.desc17 }}</li>
                        <li>{{ $store.state.translation.info.responsible_game.desc18 }}</li>
                        <li>{{ $store.state.translation.info.responsible_game.desc19 }}</li>
                        <li>{{ $store.state.translation.info.responsible_game.desc20 }}</li>
                        <li>{{ $store.state.translation.info.responsible_game.desc21 }}</li>
                        <li>{{ $store.state.translation.info.responsible_game.desc22 }}</li>
                    </ul>

                    <h3>{{ $store.state.translation.info.responsible_game.desc23 }}</h3>

                    <p>
                        {{ $store.state.translation.info.responsible_game.desc24 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc25 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc26 }}
                    </p>

                    <h3>{{ $store.state.translation.info.responsible_game.desc27 }}</h3>

                    <p>
                        {{ $store.state.translation.info.responsible_game.desc28 }}
                    </p>

                    <h3>{{ $store.state.translation.info.responsible_game.desc29 }}</h3>

                    <p>
                        {{ $store.state.translation.info.responsible_game.desc30 }}
                    </p>

                    <h3>{{ $store.state.translation.info.responsible_game.desc31 }}</h3>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc32 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc33 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc34 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc35 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc36 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc37 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc38 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc39 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc40 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc41 }}
                    </p>

                    <h3>{{ $store.state.translation.info.responsible_game.desc42 }}</h3>

                    <p>
                        {{ $store.state.translation.info.responsible_game.desc43 }}
                    </p>
                    <p>
                        <a :href="`mailto:${$config.contacts.email}`">{{ $config.contacts.email }}</a>
                    </p>
                    <p>
                        {{ $store.state.translation.info.responsible_game.desc44 }}
                    </p>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.info.responsible_game.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.info.responsible_game.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.info.responsible_game.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.info.responsible_game.meta.description
                    }
                ]
            }
        }
    }
</script>