<template>
	<div class="modal modal--restore" id="restore-password">
        <div class="modal__inner">
            <button type="button" class="btn modal__btn-close" @click="togglePopup('restore-password')">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 2L12 12M22 22L12 12M12 12L22 2L2 22" stroke="#5200FF" stroke-width="4" />
                </svg>
            </button>
            <h2 class="modal__title">{{ $store.state.translation.modal.new_password }}</h2>
            <div class="modal__content">
                <form class="form form--restore" v-on:submit.prevent="updatePassword">
                    <div class="form__group">
                        <input 
                            @input="errors.password = null"
                            v-model="data.password"
                            type="password" 
                            class="form__input" 
                            :placeholder="$store.state.translation.modal.new_password"
                            />
                        <span class="error_input" v-if="errors.password">{{ errors.password }}</span>
                    </div>
                    <div class="form__group">
                        <input 
                            @input="errors.password_confirmation = null"
                            v-model="data.password_confirmation"
                            type="password" 
                            class="form__input" 
                            :placeholder="$store.state.translation.modal.new_password_confirm"
                            />
                        <span class="error_input" v-if="errors.password_confirmation">{{ errors.password_confirmation }}</span>
                    </div>
                    <div class="form__group">
                        <button type="submit" class="btn btn--fill-rose form__btn form__btn-submit" :disabled="loader_button">
                            <preloader v-if="loader_button"/>
                            {{ $store.state.translation.modal.change_password }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loader_button: false,
                data: {
                    password: null,
                    password_confirmation: null
                },
                errors: {
                    password: false,
                    password_confirmation: false
                }
            };
        },
        methods: {
            updatePassword () {
                if(!this.data.password) {
                    this.errors.password = this.$store.state.translation.axios.filed_required
                    return
                }
                if(!this.data.password_confirmation) {
                    this.errors.password_confirmation = this.$store.state.translation.axios.filed_required
                    return
                }

                this.loader_button = true
                
                this.$axios.post('/auth/restore/action', JSON.stringify({
                    token: this.$route.query.restore_token,
                    password: this.data.password,
                    password_confirmation: this.data.password_confirmation
                }))
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            
                            localStorage.setItem('token', response.token)
                            
                            this.$axios.defaults.headers.common.Authorization = 'Bearer ' + response.token

                            this.$toast.success(response.message)

                            this.togglePopup('restore-password')

                            this.$router.push({name: 'Home'})

                            this.setUser(response.user)

                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    this.loader_button = false
                    let response = error.response.data
                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                        if('field' in response) {
                            this.errors[response.field] = response.message
                        }
                    }
                })
            }
        }
    }
</script>