<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar v-if="$store.state.user.auth"/>
                        
                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <modal-signup v-if="!$store.state.user.auth"/>
            <modal-login v-if="!$store.state.user.auth"/>
            <modal-restore v-if="!$store.state.user.auth"/>
            <modal-restore-password v-if="!$store.state.user.auth"/>

            <main>
                <cards-sidebar/>

                <section class="section section--text section--about-us">
                    <h1>{{ $store.state.translation.info.privacy_policy.title }}</h1>

                    <ol>
                        <li>
                            {{ $store.state.translation.info.privacy_policy.desc1 }}
                            <p>
                                {{ $store.state.translation.info.privacy_policy.desc2 }}
                            </p>
                            <p>
                                {{ $store.state.translation.info.privacy_policy.desc3 }}
                            </p>
                            <p>
                                {{ $store.state.translation.info.privacy_policy.desc4 }}
                            </p>
                        </li>
                        <li>
                            {{ $store.state.translation.info.privacy_policy.desc5 }}
                            <p>
                                {{ $store.state.translation.info.privacy_policy.desc6 }}
                            </p>

                            <ul>
                                <li>
                                    {{ $store.state.translation.info.privacy_policy.desc7 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.privacy_policy.desc8 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.privacy_policy.desc9 }}
                                </li>
                            </ul>
                        </li>
                        <li>
                            {{ $store.state.translation.info.privacy_policy.desc10 }}
                            <p>
                                {{ $store.state.translation.info.privacy_policy.desc11 }}
                            </p>

                            <ul>
                                <li>
                                    {{ $store.state.translation.info.privacy_policy.desc12 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.info.privacy_policy.desc13 }}
                                </li>
                            </ul>
                        </li>
                        <li>
                            {{ $store.state.translation.info.privacy_policy.desc14 }}
                            <p>
                                {{ $store.state.translation.info.privacy_policy.desc15 }} <a :href="`mailto:${$config.contacts.email}`">{{ $config.contacts.email }}</a>
                            </p>
                            <p>
                                {{ $store.state.translation.info.privacy_policy.desc16 }}
                            </p>
                        </li>
                        <li>
                            {{ $store.state.translation.info.privacy_policy.desc17 }}
                            <p>
                                {{ $store.state.translation.info.privacy_policy.desc18 }}
                            </p>
                            <p>
                                {{ $store.state.translation.info.privacy_policy.desc19 }}
                            </p>
                        </li>
                        <li>
                            {{ $store.state.translation.info.privacy_policy.desc20 }}
                            <p>
                                {{ $store.state.translation.info.privacy_policy.desc21 }}
                            </p>
                        </li>
                        <li>
                            {{ $store.state.translation.info.privacy_policy.desc22 }}
                            <p>
                                {{ $store.state.translation.info.privacy_policy.desc23 }}
                            </p>
                        </li>
                        <li>
                            {{ $store.state.translation.info.privacy_policy.desc24 }}
                            <p>
                                {{ $store.state.translation.info.privacy_policy.desc25 }}
                            </p>
                        </li>
                        <li>
                            {{ $store.state.translation.info.privacy_policy.desc26 }}
                            <p>
                                {{ $store.state.translation.info.privacy_policy.desc27 }}
                            </p>
                        </li>
                        <li>
                            {{ $store.state.translation.info.privacy_policy.desc28 }}
                            <p>
                                {{ $store.state.translation.info.privacy_policy.desc29 }} <a :href="`mailto:${$config.contacts.email}`">{{ $config.contacts.email }}</a>. 
                            </p>
                        </li>
                        <li>
                            {{ $store.state.translation.info.privacy_policy.desc30 }}
                            <p>
                                {{ $store.state.translation.info.privacy_policy.desc31 }}
                            </p>
                        </li>
                        <li>
                            {{ $store.state.translation.info.privacy_policy.desc32 }}
                            <ol>
                                <li>
                                    {{ $store.state.translation.info.privacy_policy.desc33 }}
                                    <p>
                                        {{ $store.state.translation.info.privacy_policy.desc34 }}
                                    </p>
                                </li>
                                <li>
                                    {{ $store.state.translation.info.privacy_policy.desc35 }}
                                    <p>
                                        {{ $store.state.translation.info.privacy_policy.desc36 }}
                                    </p>
                                </li>
                                <li>
                                    {{ $store.state.translation.info.privacy_policy.desc37 }}
                                    <p>
                                        {{ $store.state.translation.info.privacy_policy.desc38 }}
                                    </p>
                                </li>
                                <li>
                                    {{ $store.state.translation.info.privacy_policy.desc39 }}
                                    <ol>
                                        <li>
                                            {{ $store.state.translation.info.privacy_policy.desc40 }}
                                            <p>
                                                {{ $store.state.translation.info.privacy_policy.desc41 }}
                                            </p>
                                        </li>
                                        <li>
                                            {{ $store.state.translation.info.privacy_policy.desc42 }}
                                            <p>
                                                {{ $store.state.translation.info.privacy_policy.desc43 }}
                                            </p>
                                        </li>
                                        <li>
                                            {{ $store.state.translation.info.privacy_policy.desc44 }}
                                            <p>
                                                {{ $store.state.translation.info.privacy_policy.desc45 }}
                                            </p>
                                        </li>
                                        <li>
                                            {{ $store.state.translation.info.privacy_policy.desc46 }}
                                            <p>
                                                {{ $store.state.translation.info.privacy_policy.desc47 }}
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    {{ $store.state.translation.info.privacy_policy.desc48 }}
                                </li>
                            </ol>
                        </li>
                    </ol>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.info.privacy_policy.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.info.privacy_policy.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.info.privacy_policy.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.info.privacy_policy.meta.description
                    }
                ]
            }
        }
    }
</script>