<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar/>
                        
                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <main>
                <cards-sidebar/>

                <section class="section section--profile section--restrictions">
                    
                    <profile-mobile/>

                    <h2 class="section__title">{{ $store.state.translation.restriction.title }}</h2>

                    <div class="section__content">
                        <p class="section__text">
                            {{ $store.state.translation.restriction.text1 }}
                        </p>

                        <p class="section__text">
                            {{ $store.state.translation.restriction.text2 }}
                        </p>

                        <p class="section__text">
                            {{ $store.state.translation.restriction.text3 }}
                        </p>

                        <p class="section__text">
                            {{ $store.state.translation.restriction.text4 }}
                        </p>

                        <p class="section__text">
                            {{ $store.state.translation.restriction.text5 }}
                        </p>

                        <p class="section__text">
                            {{ $store.state.translation.restriction.text6 }}
                            
                        </p>

                        <div class="select__wrapper">
                            <div class="select select--restrictions">
                                <v-select 
                                    :options="utils.periods"
                                    v-model="period"
                                    @input="errors.period = false"
                                    :placeholder="$store.state.translation.restriction.period"
                                    label="name"
                                    class="big-vue-select account-vue-select vue-select-restrictions">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template>
                                         {{ $store.state.translation.select.nothing_found }}
                                      </template>
                                    </template>
                                </v-select>
                            </div>
                            <button 
                                @click="restraint"
                                :disabled="loader_button"
                                type="button"
                                class="btn btn--fill-rose select__submit">
                                <preloader v-if="loader_button"/>
                                {{ $store.state.translation.restriction.block }}
                            </button>
                        </div>

                        <span class="error_input" v-if="errors.period">{{ errors.period }}</span>
                    </div>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
export default {
    metaInfo () {
        return {
            title: this.$store.state.translation.restriction.meta.title,
            meta: [
                {
                    name: 'title', 
                    content: this.$store.state.translation.restriction.meta.title
                },
                {
                    name: 'keywords', 
                    content: this.$store.state.translation.restriction.meta.keywords
                },
                {
                    name: 'description', 
                    content: this.$store.state.translation.restriction.meta.description
                }
            ]
        }
    },
    data () {
        return {
            loader_button: false,
            period: {
                name: this.$store.state.translation.restriction.periods.p_30,
                key: 30
            },
            errors: {
                period: false
            },
            utils: {
                periods: [
                    {
                        name: this.$store.state.translation.restriction.periods.p_10,
                        key: 10
                    },
                    {
                        name: this.$store.state.translation.restriction.periods.p_30,
                        key: 30
                    },
                    {
                        name: this.$store.state.translation.restriction.periods.p_90,
                        key: 90
                    },
                    {
                        name: this.$store.state.translation.restriction.periods.p_180,
                        key: 180
                    },
                    {
                        name: this.$store.state.translation.restriction.periods.p_365,
                        key: 365
                    }
                ]
            }
        }
    },
    methods: {
        restraint () {

            if(!this.period.key) {
                this.$toast.error(this.$store.state.translation.restriction.select_period)
                this.errors.period = this.$store.state.translation.axios.filed_required
                return
            }

            this.loader_button = true
            
            this.$axios.post('/users/restraint', JSON.stringify({
                period: this.period.key
            }))
            .then((response) => {
                this.loader_button = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error(this.$store.state.translation.axios.unknown_error)
                }
            }).catch((error) => {
                this.loader_button = false
                let response = error.response.data
                this.setUser(response.user)

                if(response && 'status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        }
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
