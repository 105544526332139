<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar v-if="$store.state.user.auth"/>
                        
                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <modal-signup v-if="!$store.state.user.auth"/>
            <modal-login v-if="!$store.state.user.auth"/>
            <modal-restore v-if="!$store.state.user.auth"/>
            <modal-restore-password v-if="!$store.state.user.auth"/>

            <main>
                <cards-sidebar/>

                <!-- section active tournaments -->
                <section class="section section--tournaments" v-if="!tournaments.active.length">
                    <h2 class="section__title no__tournaments">{{ $store.state.translation.tournament.no_tournaments }}</h2>
                </section>
                <section class="section section--tournaments" v-if="tournaments.active.length">
                    <h2 class="section__title">{{ $store.state.translation.tournament.active }}</h2>
                    <div class="section__content">

                        <swiper
                            v-bind="swiper_options"
                            class="swiper swiper--active-tournament swiper-initialized swiper-horizontal swiper-backface-hidden">
                            <swiper-slide v-for="item in tournaments.active">
                                <article class="card card--tournament card--tournament-active">
                                    <div class="card__image-wrapper">
                                        <img :src="`${$config.ApplicationStaticURL}/tournaments/${item.image}`" class="card__image" />
                                        <div class="card__date">{{ $moment(item.start_at).format('YYYY-MM-DD') }} - {{ $moment(item.end_at).format('YYYY-MM-DD') }}</div>
                                    </div>
                                    <div class="card__content">
                                        <div class="card__timer">
                                            <span class="card__timer-caption">
                                                {{ $store.state.translation.tournament.until_end }}
                                            </span>

                                            <div class="card__timer-content">
                                                <tournament-timer 
                                                    :id="item.id" 
                                                    :end_at="item.end_at" 
                                                    @end_callback="endTournament"/>
                                            </div>
                                        </div>

                                        <h3 class="card__title">{{ item.name }}</h3>
                                        <span class="card__text">{{ $store.state.translation.tournament.prize }}</span>
                                        <span class="card__prize">{{ parseFloat(item.total_amount).toFixed(2) }} $</span>

                                        <router-link class="btn btn--purple card__btn" :to="{name: 'Tournament', params: {id: item.id}}">{{ $store.state.translation.tournament.participate }}</router-link>
                                    </div>
                                </article>
                            </swiper-slide>
                        </swiper>
                    </div>
                </section>
                <!-- end of section active tournaments-->


                <!-- section past tournaments -->
                <section class="section section--tournaments" v-if="tournaments.past.length">
                    <h2 class="section__title">{{ $store.state.translation.tournament.past }}</h2>
                    <div class="section__content">
                        <swiper
                            v-bind="swiper_options"
                            class="swiper swiper-past-tournaments">
                            <swiper-slide v-for="item in tournaments.past">
                                <article class="card card--tournament card--tournament-past">
                                    <div class="card__image-wrapper">
                                        <img :src="`${$config.ApplicationStaticURL}/tournaments/${item.image}`" class="card__image" />
                                        <div class="card__date">{{ $moment(item.start_at).format('YYYY-MM-DD') }} - {{ $moment(item.end_at).format('YYYY-MM-DD') }}</div>
                                    </div>
                                    <div class="card__content">
                                        <div class="card__timer">
                                            <span class="card__timer-caption">
                                                {{ $store.state.translation.tournament.until_end }}
                                            </span>

                                            <div class="card__timer-content">
                                                <tournament-timer 
                                                    :id="item.id" 
                                                    :end_at="$moment().format('YYYY-MM-DD')" 
                                                    @end_callback="endTournament"/>
                                            </div>
                                        </div>

                                        <h3 class="card__title">{{ item.name }}</h3>
                                        <span class="card__text">{{ $store.state.translation.tournament.prize }}</span>
                                        <span class="card__prize">{{ parseFloat(item.total_amount).toFixed(2) }} $</span>

                                        <router-link class="btn btn--purple card__btn" :to="{name: 'Tournament', params: {id: item.id}}">{{ $store.state.translation.tournament.winners }}</router-link>
                                    </div>
                                </article>
                            </swiper-slide>
                        </swiper>
                    </div>
                </section>
                <!-- end of section past tournments -->

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    import { Navigation, Pagination, Autoplay, Thumbs } from 'swiper'
    import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'

    SwiperCore.use([Navigation, Pagination, Autoplay, Thumbs])

    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.tournament.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.tournament.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.tournament.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.tournament.meta.description
                    }
                ]
            }
        },
        components: {
            Swiper,
            SwiperSlide,
        },
        data() {
            return {
                data: {
                    limit: 10,
                    offset: 0
                },
                tournaments: {
                    active: [],
                    past: []
                },
                swiper_options: {
                    direction: 'horizontal',
                    observer: true,
                    slidesPerView: 1,
                    spaceBetween: 40,
                    slidesPerColumn: 1,
                    breakpoints: {
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        576: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                    }
                }
            }
        },
        methods: {
            getTournaments () {
                this.appLoader('create', 100)
                this.$axios.post('/tournaments/items', JSON.stringify(this.data))
                .then((response) => {
                    this.appLoader('remove')
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            for (let item of response.tournaments) {
                                if(item.status == 0) {
                                    this.tournaments.active.push(item)
                                } else if(item.status == 1) {
                                    this.tournaments.past.push(item)
                                }
                            }

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            endTournament (id) {
                let index = this.tournaments.active.findIndex((el) => el.id == id && status == 0)
                if(index > -1) {
                    this.tournaments.active[index].status = 1
                    this.tournaments.past.push(this.tournaments.active[index])
                    this.tournaments.active.splice(index, 1)
                }
            },
        },
        beforeMount () {
            this.getTournaments()
        }
    }
</script>
