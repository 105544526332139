<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar/>
                        
                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <main>
                <cards-sidebar/>

                <section class="section section--profile section--verification">
                    
                    <profile-mobile/>

                    <h2 class="section__title">{{ $store.state.translation.verification.title }}</h2>

                    <div class="section__content">
                        <div class="progress">
                            <div class="progress__item progress__item--base" :class="{'active': page >= 1}">
                                <div class="progress__item-bar"></div>
                                <span class="progress__item-text">
                                    {{ $store.state.translation.verification.step1 }}
                                </span>
                                <span class="progress__item-text-sm">
                                    {{ $store.state.translation.verification.step }} 1
                                </span>
                            </div>
                            <div class="progress__item progress__item--docs" :class="{'active': page >= 2}">
                                <div class="progress__item-bar"></div>
                                <span class="progress__item-text">
                                    {{ $store.state.translation.verification.step2 }}
                                </span>
                                <span class="progress__item-text-sm">
                                    {{ $store.state.translation.verification.step }} 2
                                </span>
                            </div>
                            <div class="progress__item progress__item--selfie" :class="{'active': page >= 3}">
                                <div class="progress__item-bar"></div>
                                <span class="progress__item-text">
                                    {{ $store.state.translation.verification.step3 }}
                                </span>
                                <span class="progress__item-text-sm">
                                    {{ $store.state.translation.verification.step }} 3
                                </span>
                            </div>
                            <div class="progress__item progress__item--success" :class="{'active': page >= 4}">
                                <div class="progress__item-bar"></div>
                                <span class="progress__item-text">
                                    {{ $store.state.translation.verification.step4 }}
                                </span>
                                <span class="progress__item-text-sm">
                                    {{ $store.state.translation.verification.step }} 4
                                </span>
                            </div>
                        </div>

                        <section class="section section__profile section--verify-base active" v-if="page == 1">
                            <h4 class="section__title-2">
                                {{ $store.state.translation.verification.basic }}
                            </h4>

                            <div class="section__content">
                                <p class="section__text">
                                    <span class="highlight">{{ $store.state.translation.verification.important }}</span>
                                    {{ $store.state.translation.verification.important_info }}
                                </p>

                                <div class="form form--verify">
                                    <div class="form__group">
                                        <label for="v-input" class="form__label">
                                            <span>{{ $store.state.translation.verification.citizenship }} <span class="red">*</span></span>

                                            <div class="select select--verification">
                                                <v-select 
                                                    :options="utils.countries"
                                                    v-model="data.country"
                                                    @input="errors.country = false"
                                                    :placeholder="$store.state.translation.verification.citizenship"
                                                    label="name"
                                                    class="big-vue-select account-vue-select vue-select-verification">
                                                    <template v-slot:no-options="{ search, searching }">
                                                      <template>
                                                         {{ $store.state.translation.select.nothing_found }}
                                                      </template>
                                                    </template>
                                                </v-select>
                                            </div>
                                            <span class="error_input" v-if="errors.country">{{ errors.country }}</span>
                                        </label>
                                    </div>
                                    <div class="form__group">
                                        <label for="pd-input-rep-pas" class="form__label">
                                            <span>{{ $store.state.translation.verification.type_doc }} <span class="red">*</span></span>

                                            <div class="select select--verification">
                                                <v-select 
                                                    :options="utils.doc_types"
                                                    v-model="data.type"
                                                    @input="errors.type = false"
                                                    :placeholder="$store.state.translation.verification.type_doc"
                                                    label="name"
                                                    class="big-vue-select account-vue-select vue-select-verification">
                                                    <template v-slot:no-options="{ search, searching }">
                                                      <template>
                                                         {{ $store.state.translation.select.nothing_found }}
                                                      </template>
                                                    </template>
                                                </v-select>
                                            </div>
                                            <span class="error_input" v-if="errors.type">{{ errors.type }}</span>
                                        </label>
                                    </div>
                                    <div class="form__group">
                                        <label class="form__label">
                                            <span>{{ $store.state.translation.verification.number_doc }} <span class="red">*</span></span>
                                            <input
                                                v-model="data.doc_id"
                                                @input="errors.doc_id = false"
                                                type="text"
                                                class="form__input"
                                                placeholder="№"/>
                                            <span class="error_input" v-if="errors.doc_id">{{ errors.doc_id }}</span>
                                        </label>
                                    </div>
                                    <div class="form__group">
                                        <label for="v-input-doc-valid-period" class="form__label">
                                            <span>{{ $store.state.translation.verification.expire_doc }} <span class="red">*</span></span>

                                            <input
                                                v-model="data.expire"
                                                @change="expireValidate"
                                                @input="errors.expire = false"
                                                v-mask="'####-##-##'"
                                                placeholder="2023-11-11"
                                                maxlength="10"
                                                type="text" 
                                                class="form__input"/>
                                                <span class="error_input" v-if="errors.expire">{{ errors.expire }}</span>
                                        </label>
                                    </div>
                                    <div class="form__group">
                                        <label for="v-input-surname" class="form__label">
                                            <span>{{ $store.state.translation.verification.lastname }} <span class="red">*</span></span>

                                            <input
                                                v-model="data.last_name"
                                                @input="errors.last_name = false"
                                                type="text"
                                                class="form__input"
                                                :placeholder="$store.state.translation.verification.lastname_placeholder"/>
                                            <span class="error_input" v-if="errors.last_name">{{ errors.last_name }}</span>
                                        </label>
                                    </div>
                                    <div class="form__group">
                                        <label for="v-input-name" class="form__label">
                                            <span>{{ $store.state.translation.verification.firstname }} <span class="red">*</span></span>

                                            <input
                                                v-model="data.first_name"
                                                @input="errors.first_name = false"
                                                type="text"
                                                class="form__input"
                                                :placeholder="$store.state.translation.verification.firstname_placeholder"/>
                                            <span class="error_input" v-if="errors.first_name">{{ errors.first_name }}</span>
                                        </label>
                                    </div>
                                    <div class="form__group form__group--radio-list">
                                        <label for="v-input" class="form__label">
                                            <span>{{ $store.state.translation.verification.sex }} <span class="red">*</span></span>

                                            <div class="form__radio-list">
                                                <label class="form__radio-wrapper" for="v-input-gender-m">
                                                    <input 
                                                        v-model="data.sex"
                                                        @input="errors.sex = false"
                                                        value="1"
                                                        id="v-input-gender-m" 
                                                        type="radio" 
                                                        name="gender" 
                                                        class="form__radio" 
                                                        checked=""/>
                                                    <div class="form__radio-btn">{{ $store.state.translation.verification.sex_m }}</div>
                                                </label>
                                                <label class="form__radio-wrapper" for="v-input-gender-f">
                                                    <input 
                                                        v-model="data.sex"
                                                        @input="errors.sex = false"
                                                        value="2"
                                                        id="v-input-gender-f" 
                                                        type="radio" 
                                                        name="gender" 
                                                        class="form__radio"/>
                                                    <div class="form__radio-btn">{{ $store.state.translation.verification.sex_f }}</div>
                                                </label>
                                            </div>
                                            <span class="error_input" v-if="errors.sex">{{ errors.sex }}</span>
                                        </label>
                                    </div>
                                    <button type="button" class="btn btn--fill-rose form__submit btn-base-next" @click="checkPage(1)">
                                        {{ $store.state.translation.verification.next }}

                                        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                                fill="white"
                                            ></path>
                                            <path
                                                d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                                fill="white"
                                            ></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </section>

                        <section class="section section--profile section--verify-docs active" v-if="page == 2">
                            <h4 class="section__title-2">{{ $store.state.translation.verification.upload_doc }}</h4>
                            <div class="section__cotent">
                                <p class="section__text">
                                    <span class="highlight">{{ $store.state.translation.verification.important }}</span>
                                </p>

                                <ul>
                                    <li>{{ $store.state.translation.verification.doc_term1 }}</li>
                                    <li>{{ $store.state.translation.verification.doc_term2 }}</li>
                                    <li>{{ $store.state.translation.verification.doc_term3 }}</li>
                                    <li>{{ $store.state.translation.verification.doc_term4 }}</li>
                                    <li>{{ $store.state.translation.verification.doc_term5 }}</li>
                                </ul>

                                <div class="form--verify-docs">
                                    <div class="form__group form__group--file">
                                        <div class="form__label">
                                            <span>{{ $store.state.translation.verification.upload_doc1 }}</span>
                                            <label for="vd-input-file" class="file-upload">
                                                <input 
                                                    @input="setDoc" 
                                                    accept=".png, .jpg, .jpeg, .gif, .pdf"
                                                    type="file" 
                                                    ref="file_doc"
                                                    id="vd-input-file" 
                                                    class="file-upload__input" />

                                                <div class="file-upload__field-wrapper">
                                                    <div class="file-upload__icon">
                                                        <img src="../../assets/img/icons/download.svg"/>
                                                    </div>
                                                    <div class="file-upload__name" v-if="!data.doc.name">{{ $store.state.translation.verification.select_file }}</div>
                                                    <div class="file-upload__name cwhite" v-else>{{ data.doc.name }}</div>
                                                </div>
                                            </label>
                                        </div>
                                        <span class="error_input" v-if="errors.doc">{{ errors.doc }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="section__btns">
                                <button type="button" class="btn btn--outline-rose btn-docs-back" @click="page--">
                                    <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M0.722183 9.27782C0.292606 8.84824 0.292606 8.15176 0.722183 7.72218L7.72254 0.721825C8.15212 0.292249 8.8486 0.292249 9.27817 0.721825C9.70775 1.1514 9.70775 1.84788 9.27817 2.27746L3.05564 8.5L9.27817 14.7225C9.70775 15.1521 9.70775 15.8486 9.27817 16.2782C8.8486 16.7078 8.15212 16.7078 7.72254 16.2782L0.722183 9.27782ZM21.5 9.6H1.5V7.4H21.5V9.6Z"
                                            fill="#FF00E5"
                                        ></path>
                                    </svg>

                                    {{ $store.state.translation.verification.back }}
                                </button>
                                <button type="button" class="btn btn--fill-rose btn-docs-next" @click="checkPage(2)">
                                    {{ $store.state.translation.verification.next }}
                                    <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                            fill="white"
                                        ></path>
                                        <path
                                            d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                            fill="white"
                                        ></path>
                                    </svg>
                                </button>
                            </div>
                        </section>
                        <section class="section section--profile section--verify-selfie active" v-if="page == 3">
                            <h4 class="section__title-2">{{ $store.state.translation.verification.face_recognition }}</h4>
                            <p class="section__text white">{{ $store.state.translation.verification.make_selfie }}</p>
                            <div class="section__cotent">
                                <p class="section__text">
                                    <span class="highlight">{{ $store.state.translation.verification.important }}</span>
                                </p>

                                <ul>
                                    <li>{{ $store.state.translation.verification.doc_term1 }}</li>
                                    <li>{{ $store.state.translation.verification.doc_term2 }}</li>
                                    <li>{{ $store.state.translation.verification.doc_term3 }}</li>
                                    <li>{{ $store.state.translation.verification.doc_term4 }}</li>
                                    <li>{{ $store.state.translation.verification.doc_term5 }}</li>
                                </ul>

                                <div class="form--verify-docs">
                                    <div class="form__group form__group--file">
                                        <div class="form__label">
                                            <span>{{ $store.state.translation.verification.upload_doc2 }}</span>
                                            <label for="vd-input-file-selfie" class="file-upload">
                                                <input 
                                                    @input="setSelfie" 
                                                    accept=".png, .jpg, .jpeg, .gif, .pdf"
                                                    type="file" 
                                                    ref="file_selfie"
                                                    id="vd-input-file-selfie" 
                                                    class="file-upload__input" />

                                                <div class="file-upload__field-wrapper">
                                                    <div class="file-upload__icon">
                                                        <img src="../../assets/img/icons/download.svg"/>
                                                    </div>
                                                    <div class="file-upload__name" v-if="!data.selfie.name">{{ $store.state.translation.verification.select_file }}</div>
                                                    <div class="file-upload__name cwhite" v-else>{{ data.selfie.name }}</div>
                                                </div>
                                            </label>
                                        </div>
                                        <span class="error_input" v-if="errors.selfie">{{ errors.selfie }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="section__btns">
                                <button type="button" class="btn btn--outline-rose btn-selfie-back" @click="page--">
                                    <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M0.722183 9.27782C0.292606 8.84824 0.292606 8.15176 0.722183 7.72218L7.72254 0.721825C8.15212 0.292249 8.8486 0.292249 9.27817 0.721825C9.70775 1.1514 9.70775 1.84788 9.27817 2.27746L3.05564 8.5L9.27817 14.7225C9.70775 15.1521 9.70775 15.8486 9.27817 16.2782C8.8486 16.7078 8.15212 16.7078 7.72254 16.2782L0.722183 9.27782ZM21.5 9.6H1.5V7.4H21.5V9.6Z"
                                            fill="#FF00E5"
                                        ></path>
                                    </svg>

                                    {{ $store.state.translation.verification.back }}
                                </button>
                                <button 
                                    :disabled="loader_button"
                                    type="button" 
                                    class="btn btn--fill-rose btn-selfie-next" 
                                    @click="checkPage(3)">
                                    <preloader v-if="loader_button"/>
                                    {{ $store.state.translation.verification.next }}
                                    <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                            fill="white"
                                        ></path>
                                        <path
                                            d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                            fill="white"
                                        ></path>
                                    </svg>
                                </button>
                            </div>
                        </section>

                        <section class="section section--profile section--verify-success active" v-if="page == 4">
                            <img src="../../assets/img/icons/check-circle.svg" class="section__icon" />

                            <h4 class="section__title">{{ $store.state.translation.verification.accepted_doc }}</h4>
                            <div class="section__content">
                                <p class="section__text">
                                    {{ $store.state.translation.verification.pending_doc }}
                                </p>
                            </div>

                            <router-link :to="{name: 'Home'}" class="btn btn--fill-rose section__btn">{{ $store.state.translation.verification.to_home }}</router-link>
                        </section>

                        <section class="section section--profile section--verify-success active" v-if="page == 5">
                            <img src="../../assets/img/icons/check-circle.svg" class="section__icon" />

                            <h4 class="section__title">{{ $store.state.translation.verification.accepted_doc }}</h4>
                            <div class="section__content">
                                <p class="section__text">
                                    {{ $store.state.translation.verification.verified_doc }}
                                </p>
                            </div>

                            <router-link :to="{name: 'Home'}" class="btn btn--fill-rose section__btn">{{ $store.state.translation.verification.to_home }}</router-link>
                        </section>
                    </div>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
export default {
    metaInfo () {
        return {
            title: this.$store.state.translation.verification.meta.title,
            meta: [
                {
                    name: 'title', 
                    content: this.$store.state.translation.verification.meta.title
                },
                {
                    name: 'keywords', 
                    content: this.$store.state.translation.verification.meta.keywords
                },
                {
                    name: 'description', 
                    content: this.$store.state.translation.verification.meta.description
                }
            ]
        }
    },
    data () {
        return {
            loader_button: false,
            page: 1,
            kyc: {
                verified: false,
                on_verification: false
            },
            data: {
                country: null,
                type: null,
                doc_id: null,
                expire: null,
                last_name: null,
                first_name: null,
                sex: null,
                doc: {
                    name: null,
                    file: null
                },
                selfie: {
                    name: null,
                    file: null
                }
            },
            errors: {
                country: false,
                type: false,
                doc_id: false,
                expire: false,
                last_name: false,
                first_name: false,
                sex: false,
                doc: false,
                selfie: false
            },
            utils: {
                countries: [],
                doc_types: [
                    {
                        name: this.$store.state.translation.verification.doc_types.passport,
                        key: 'passport'
                    },
                    {
                        name: this.$store.state.translation.verification.doc_types.driver_id,
                        key: 'driver_id'
                    },
                    {
                        name: this.$store.state.translation.verification.doc_types.id_card,
                        key: 'id_card'
                    }
                ]
            }
        }
    },
    methods: {
        async checkKyc () {
            this.appLoader('create', 100)
            await this.$axios.post('/users/kyc/check')
            .then((response) => {
                this.appLoader('remove')
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':

                        this.kyc = {
                            verified: response.verified,
                            on_verification: response.on_verification
                        }

                        if(this.kyc.verified == true) {
                            this.page = 5
                        }

                        if(this.kyc.on_verification == true) {
                            this.page = 4
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error(this.$store.state.translation.axios.unknown_error)
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if(response && 'status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        uploadData () {
            let formData = this.objectToFormData(new FormData(), {
                country: this.data.country.key,
                type: this.data.type.key,
                doc_id: this.data.doc_id,
                expire: this.data.expire,
                last_name: this.data.last_name,
                first_name: this.data.first_name,
                sex: this.data.sex,
                doc: this.data.doc.file,
                selfie: this.data.selfie.file
            })

            this.loader_button = true
            this.$axios.post('/users/kyc/upload', formData)
            .then((response) => {
                this.loader_button = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":

                        this.page++

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error(this.$store.state.translation.axios.unknown_error)
                }
            }).catch((error) => {
                this.loader_button = false
                let response = error.response.data
                this.setUser(response.user)

                if(response && 'status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }

                if('field' in response) {
                    this.errors[response.field] = response.message

                    if(['country', 'type', 'doc_id', 'expire', 'last_name', 'first_name', 'sex'].includes(response.field)) {
                        this.page = 1
                    }
                    if(['doc'].includes(response.field)) {
                        this.page = 2
                    }
                    if(['selfie'].includes(response.field)) {
                        this.page = 3
                    }
                }
            })
        },
        async getCountries () {
            this.appLoader('create', 100)
            await this.$axios.post('/utils/countries/items')
            .then((response) => {
                this.appLoader('remove')
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':

                        for(let item in response.data) {
                            this.utils.countries.push({
                                name: response.data[item],
                                key: item
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error(this.$store.state.translation.axios.unknown_error)
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if(response && 'status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        setDoc (e) {
            var file = e.target.files[0]

            if(file) {
                this.data.doc.name = null
                this.data.doc.file = null

                if (file.size > 5000000) {
                    this.errors.doc = this.$store.state.translation.verification.size_mb
                    return
                }

                var th = this
                var reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    var image = new Image()
                    image.src = e.target.result
                    image.onload = () => {
                      var {height, width} = image
                      if (height < 440 || width < 660) {
                        th.errors.doc = th.$store.state.translation.verification.size
                        return
                      }

                      th.data.doc.name = file.name
                      th.data.doc.file = file

                      th.errors.doc = false
                      return
                    }
                }

                this.$refs.file_doc.value = null
            }
        },
        setSelfie (e) {
            var file = e.target.files[0]

            if(file) {
                this.data.selfie.name = null
                this.data.selfie.file = null

                if (file.size > 5000000) {
                    this.errors.selfie = this.$store.state.translation.verification.size_mb
                    return
                }

                var th = this
                var reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    var image = new Image()
                    image.src = e.target.result
                    image.onload = () => {
                      var {height, width} = image
                      if (height < 440 || width < 660) {
                        th.errors.selfie = th.$store.state.translation.verification.size
                        return
                      }

                      th.data.selfie.name = file.name
                      th.data.selfie.file = file

                      th.errors.selfie = false
                      return
                    }
                }

                this.$refs.file_selfie.value = null
            }
        },
        expireValidate() {
            var expire = this.$moment(this.data.expire)

            if (!expire.isValid()) {
                this.errors.expire = this.$store.state.translation.verification.invalid_date
                return true
            }

            return false
        },
        checkPage (page) {
            if(page == 1) {
                if(!this.data.country) {
                    this.errors.country = this.$store.state.translation.axios.filed_required
                    return
                }
                if(!this.data.type) {
                    this.errors.type = this.$store.state.translation.axios.filed_required
                    return
                }
                if(!this.data.doc_id) {
                    this.errors.doc_id = this.$store.state.translation.axios.filed_required
                    return
                }
                if(!this.data.expire) {
                    this.errors.expire = this.$store.state.translation.axios.filed_required
                    return
                }
                if(this.expireValidate()) {
                    return
                }
                if(!this.data.last_name) {
                    this.errors.last_name = this.$store.state.translation.axios.filed_required
                    return
                }
                if(!this.data.first_name) {
                    this.errors.first_name = this.$store.state.translation.axios.filed_required
                    return
                }
                if(!this.data.sex) {
                    this.errors.sex = this.$store.state.translation.axios.filed_required
                    return
                }
            }

            if(page == 2) {
                if(!this.data.doc.name) {
                    this.errors.doc = this.$store.state.translation.axios.filed_required
                    return
                }
            }

            if(page == 3) {
                if(!this.data.selfie.name) {
                    this.errors.selfie = this.$store.state.translation.axios.filed_required
                    return
                }

                this.uploadData()
            }

            if(page < 3) {
                this.page++
            }
        },
        objectToFormData(formData, data, root) {
            root = root || ''
            if (data instanceof File) {
                formData.append(root, data)
            } else if (Array.isArray(data)) {
                for (var i = 0; i < data.length; i++) {
                    this.objectToFormData(formData, data[i], root + '[' + i + ']')
                }
            } else if (typeof data === 'object' && data) {
                let i = 0
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            this.objectToFormData(formData, data[key], key)
                        } else {
                            this.objectToFormData(formData, data[key], root + '[' + key + ']')
                        }
                    }
                    i++
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data)
                }
            }

            return formData
        }
    },
    async beforeMount () {
        await this.checkKyc()

        if(this.kyc.verified == false && this.kyc.on_verification == false) {
            await this.getCountries()
        }
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>

