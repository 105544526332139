<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">           
                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <modal-signup/>
            <modal-login/>
            <modal-restore/>
            <modal-restore-password/>

            <main>
                <cards-sidebar/>

                <section class="section section--profile section--restrictions">
                    
                    <div class="section__content">
                        <!-- section banner -->
                        <section class="section section--banner section--banner-vip">
                            <div class="section__content">
                                <div class="section__title">
                                    <p>{{ $store.state.translation.vip.title }}</p>
                                    <span>{{ $store.state.translation.vip.exclusive }}</span>
                                </div>

                                <a class="btn btn--purple section__btn" @click="$refs.about_vip.scrollIntoView()">{{ $store.state.translation.vip.discover }}</a>
                            </div>
                        </section>
                        <!-- end of section banner -->

                        <!-- section vip programm -->
                        <section class="section section--profile section--vip-programm">
                            <h2 class="section__title">{{ $store.state.translation.vip.program }}</h2>
                            <div class="section__content">

                                <section class="section section--profile section--about-vip">
                                    <div class="section__image-block">
                                        <article class="card card--vip">

                                            <img src="../assets/img/icons/vip-bronze.svg" class="card__badge" />

                                            <h6 class="card__title">{{ $store.state.translation.vip.bronze }}</h6>
                                        </article>
                                    </div>
                                    <div class="section__text-block">
                                        <h3 class="section__title">{{ $store.state.translation.vip.about }}</h3>
                                        <div class="section__text">
                                            {{ $store.state.translation.vip.desc1 }}
                                            <br />
                                            {{ $store.state.translation.vip.desc2 }}
                                        </div>
                                    </div>
                                </section>

                                <section class="section section--profile section--advantages-vip" ref="about_vip">
                                    <h2 class="section__title">{{ $store.state.translation.vip.benefits }}</h2>
                                    <div class="section__content">
                                        <div class="table__wrapper">
                                            <table class="table table--exp">
                                                <thead>
                                                    <tr>
                                                        <th>{{ $store.state.translation.vip.level }}</th>
                                                        <th>{{ $store.state.translation.vip.amount_xp }}</th>
                                                        <th>{{ $store.state.translation.vip.cashback }}</th>
                                                        <th>{{ $store.state.translation.vip.privilege }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in $store.state.promo_statuses">
                                                        <td>
                                                            <div class="table__cell-inner">
                                                                <template v-if="item.id == 1">
                                                                    <img src="../assets/img/icons/vip-bronze.svg" :alt="item.name" class="table__image" />
                                                                </template>
                                                                <template v-else-if="item.id == 2">
                                                                    <img src="../assets/img/icons/vip-silver.svg" :alt="item.name" class="table__image" />
                                                                </template>
                                                                <template v-else-if="item.id == 3">
                                                                    <img src="../assets/img/icons/vip-gold.svg" :alt="item.name" class="table__image" />
                                                                </template>
                                                                <template v-else-if="item.id == 4">
                                                                    <img src="../assets/img/icons/vip-platinum.svg" :alt="item.name" class="table__image" />
                                                                </template>
                                                                <template v-else-if="item.id == 5">
                                                                    <img src="../assets/img/icons/vip-diamond.svg" :alt="item.name" class="table__image" />
                                                                </template>

                                                                {{ item.id }}. {{ item.name }}
                                                            </div>
                                                        </td>
                                                        <td>{{ parseFloat(item.bets) / 10 }}+</td>
                                                        <td>{{ item.cashback }}%</td>
                                                        <td>
                                                            {{ $sprintf($store.state.translation.vip.max_payout, item.max_withdrawal, $store.state.user.data.merchant.currency) }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="acc-history acc-history--exp">
                                            <div class="acc-history__item" v-for="(item, index) in $store.state.promo_statuses">
                                                <ul>
                                                    <li>
                                                        <span class="acc-history__item-caption">{{ $store.state.translation.vip.level }}</span>
                                                        <span class="acc-history__item-value">
                                                            <template v-if="item.id == 1">
                                                                <img src="../assets/img/icons/vip-bronze.svg" :alt="item.name" class="table__image" />
                                                            </template>
                                                            <template v-else-if="item.id == 2">
                                                                <img src="../assets/img/icons/vip-silver.svg" :alt="item.name" class="table__image" />
                                                            </template>
                                                            <template v-else-if="item.id == 3">
                                                                <img src="../assets/img/icons/vip-gold.svg" :alt="item.name" class="table__image" />
                                                            </template>
                                                            <template v-else-if="item.id == 4">
                                                                <img src="../assets/img/icons/vip-platinum.svg" :alt="item.name" class="table__image" />
                                                            </template>
                                                            <template v-else-if="item.id == 5">
                                                                <img src="../assets/img/icons/vip-diamond.svg" :alt="item.name" class="table__image" />
                                                            </template>

                                                            {{ item.id }}. {{ item.name }}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span class="acc-history__item-caption">{{ $store.state.translation.vip.amount_xp }}</span>
                                                        <span class="acc-history__item-value">{{ parseFloat(item.bets) / 10 }}+</span>
                                                    </li>
                                                    <li>
                                                        <span class="acc-history__item-caption">{{ $store.state.translation.vip.cashback }}</span>
                                                        <span class="acc-history__item-value">{{ item.cashback }}%</span>
                                                    </li>
                                                    <li>
                                                        <span class="acc-history__item-caption">{{ $store.state.translation.vip.privilege }}</span>
                                                        <span class="acc-history__item-value">
                                                            {{ $sprintf($store.state.translation.vip.max_payout, item.max_withdrawal, $store.state.user.data.merchant.currency) }}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </section>
                    </div>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.vip.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.vip.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.vip.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.vip.meta.description
                    }
                ]
            }
        },
        data() {
            return {
                status: {
                    status: {
                        id: null,
                        name: null
                    },
                    percent: 0,
                    bets: 0,
                    need_bets: 0,
                    next_status: {
                        id: null,
                        name: null
                    }
                }
            }
        },
        async beforeMount () {
            if(!this.$store.state.promo_statuses.length) {
                this.appLoader('create', 100)
                await this.getVipStatuses()
                this.appLoader('remove')
            }
        }
    }
</script>

