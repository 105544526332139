<template>
	<section class="section section--profile">
        <h4 class="section__title">{{ $store.state.translation.profile.change_pass_title }}</h4>

        <div class="section__content">
            <form class="form form--profile form--change-pas" v-on:submit.prevent="updatePassword">
                <div class="form__group">
                    <label for="pd-input-old-pas" class="form__label">
                        <span>{{ $store.state.translation.profile.password.old_pass }} <span class="red">*</span></span>

                        <div class="form__input-pas-wrapper">
                            <input
                                v-model="data.password.password_old"
                                @input="errors.password.password_old = false" 
                                :type="hide.password_old ? 'password' : 'text'"
                                class="form__input form__input--pas"/>

                            <button type="button" class="btn btn-show-password" @click="hide.password_old = !hide.password_old">
                                <svg width="30" height="18" viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="hide.password_old">
                                    <path
                                        d="M15 0.0585938C9.26818 0.0585938 4.07027 3.19453 0.234735 8.28811C-0.0782449 8.70542 -0.0782449 9.28842 0.234735 9.70573C4.07027 14.8054 9.26818 17.9414 15 17.9414C20.7318 17.9414 25.9297 14.8054 29.7653 9.71186C30.0782 9.29455 30.0782 8.71156 29.7653 8.29425C25.9297 3.19453 20.7318 0.0585938 15 0.0585938ZM15.4112 15.2964C11.6063 15.5357 8.46425 12.3998 8.70359 8.58882C8.89997 5.44675 11.4468 2.89996 14.5888 2.70358C18.3937 2.46424 21.5357 5.60017 21.2964 9.41116C21.0939 12.5471 18.5471 15.0939 15.4112 15.2964ZM15.2209 12.3875C13.1712 12.5164 11.4774 10.8288 11.6125 8.77906C11.7168 7.08529 13.0914 5.71677 14.7852 5.60631C16.8349 5.47743 18.5287 7.16507 18.3937 9.21478C18.2832 10.9147 16.9086 12.2832 15.2209 12.3875Z"
                                        fill="#958BA3"
                                    ></path>
                                </svg>
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!hide.password_old">
                                    <path
                                        d="M25.0378 4.96223C24.862 4.78648 24.6236 4.68774 24.375 4.68774C24.1264 4.68774 23.888 4.78648 23.7122 4.96223L20.7365 7.93786C18.9484 7.0642 16.9899 6.59466 15 6.56254C7.38185 6.56254 1.38841 14.101 1.13716 14.4216C1.00776 14.5867 0.937439 14.7903 0.937439 15C0.937439 15.2098 1.00776 15.4134 1.13716 15.5785C2.95061 17.7701 5.11153 19.6491 7.53372 21.1407L4.96216 23.7122C4.87262 23.7987 4.8012 23.9022 4.75207 24.0165C4.70293 24.1309 4.67707 24.2539 4.67599 24.3784C4.67491 24.5029 4.69863 24.6263 4.74577 24.7416C4.7929 24.8568 4.86252 24.9615 4.95054 25.0495C5.03856 25.1375 5.14324 25.2071 5.25845 25.2543C5.37367 25.3014 5.49712 25.3251 5.6216 25.324C5.74608 25.3229 5.8691 25.2971 5.98348 25.248C6.09786 25.1988 6.2013 25.1274 6.28779 25.0379L25.0378 6.28786C25.2135 6.11205 25.3123 5.87364 25.3123 5.62504C25.3123 5.37645 25.2135 5.13804 25.0378 4.96223ZM10.3125 15C10.314 13.7573 10.8083 12.5659 11.6871 11.6871C12.5658 10.8084 13.7572 10.314 15 10.3125C15.914 10.319 16.8053 10.5985 17.5593 11.115L11.115 17.5594C10.5984 16.8053 10.319 15.9141 10.3125 15Z"
                                        fill="#958BA3"
                                    ></path>
                                    <path
                                        d="M28.8628 14.4215C27.4602 12.722 25.8487 11.2063 24.0665 9.91028L19.6219 14.355C19.659 14.5681 19.6809 14.7837 19.6875 15C19.686 16.2427 19.1917 17.4341 18.3129 18.3129C17.4341 19.1916 16.2427 19.686 15 19.6875C14.7837 19.6809 14.5682 19.659 14.355 19.6218L11.1675 22.8093C12.4052 23.2142 13.6978 23.4261 15 23.4375C22.6181 23.4375 28.6115 15.899 28.8628 15.5784C28.9922 15.4134 29.0625 15.2097 29.0625 15C29.0625 14.7902 28.9922 14.5866 28.8628 14.4215Z"
                                        fill="#958BA3"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <span class="error_input" v-if="errors.password.password_old">{{ errors.password.password_old }}</span>
                    </label>
                </div>
                <div class="form__group">
                    <label for="pd-input-new-pas" class="form__label">
                        <span>{{ $store.state.translation.profile.password.new_pass }} <span class="red">*</span></span>

                        <div class="form__input-pas-wrapper">
                            <input
                                v-model="data.password.password"
                                @input="errors.password.password = false" 
                                :type="hide.password ? 'password' : 'text'"
                                class="form__input form__input--pas"/>

                            <button type="button" class="btn btn-show-password" @click="hide.password = !hide.password">
                                <svg width="30" height="18" viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="hide.password">
                                    <path
                                        d="M15 0.0585938C9.26818 0.0585938 4.07027 3.19453 0.234735 8.28811C-0.0782449 8.70542 -0.0782449 9.28842 0.234735 9.70573C4.07027 14.8054 9.26818 17.9414 15 17.9414C20.7318 17.9414 25.9297 14.8054 29.7653 9.71186C30.0782 9.29455 30.0782 8.71156 29.7653 8.29425C25.9297 3.19453 20.7318 0.0585938 15 0.0585938ZM15.4112 15.2964C11.6063 15.5357 8.46425 12.3998 8.70359 8.58882C8.89997 5.44675 11.4468 2.89996 14.5888 2.70358C18.3937 2.46424 21.5357 5.60017 21.2964 9.41116C21.0939 12.5471 18.5471 15.0939 15.4112 15.2964ZM15.2209 12.3875C13.1712 12.5164 11.4774 10.8288 11.6125 8.77906C11.7168 7.08529 13.0914 5.71677 14.7852 5.60631C16.8349 5.47743 18.5287 7.16507 18.3937 9.21478C18.2832 10.9147 16.9086 12.2832 15.2209 12.3875Z"
                                        fill="#958BA3"
                                    ></path>
                                </svg>
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!hide.password">
                                    <path
                                        d="M25.0378 4.96223C24.862 4.78648 24.6236 4.68774 24.375 4.68774C24.1264 4.68774 23.888 4.78648 23.7122 4.96223L20.7365 7.93786C18.9484 7.0642 16.9899 6.59466 15 6.56254C7.38185 6.56254 1.38841 14.101 1.13716 14.4216C1.00776 14.5867 0.937439 14.7903 0.937439 15C0.937439 15.2098 1.00776 15.4134 1.13716 15.5785C2.95061 17.7701 5.11153 19.6491 7.53372 21.1407L4.96216 23.7122C4.87262 23.7987 4.8012 23.9022 4.75207 24.0165C4.70293 24.1309 4.67707 24.2539 4.67599 24.3784C4.67491 24.5029 4.69863 24.6263 4.74577 24.7416C4.7929 24.8568 4.86252 24.9615 4.95054 25.0495C5.03856 25.1375 5.14324 25.2071 5.25845 25.2543C5.37367 25.3014 5.49712 25.3251 5.6216 25.324C5.74608 25.3229 5.8691 25.2971 5.98348 25.248C6.09786 25.1988 6.2013 25.1274 6.28779 25.0379L25.0378 6.28786C25.2135 6.11205 25.3123 5.87364 25.3123 5.62504C25.3123 5.37645 25.2135 5.13804 25.0378 4.96223ZM10.3125 15C10.314 13.7573 10.8083 12.5659 11.6871 11.6871C12.5658 10.8084 13.7572 10.314 15 10.3125C15.914 10.319 16.8053 10.5985 17.5593 11.115L11.115 17.5594C10.5984 16.8053 10.319 15.9141 10.3125 15Z"
                                        fill="#958BA3"
                                    ></path>
                                    <path
                                        d="M28.8628 14.4215C27.4602 12.722 25.8487 11.2063 24.0665 9.91028L19.6219 14.355C19.659 14.5681 19.6809 14.7837 19.6875 15C19.686 16.2427 19.1917 17.4341 18.3129 18.3129C17.4341 19.1916 16.2427 19.686 15 19.6875C14.7837 19.6809 14.5682 19.659 14.355 19.6218L11.1675 22.8093C12.4052 23.2142 13.6978 23.4261 15 23.4375C22.6181 23.4375 28.6115 15.899 28.8628 15.5784C28.9922 15.4134 29.0625 15.2097 29.0625 15C29.0625 14.7902 28.9922 14.5866 28.8628 14.4215Z"
                                        fill="#958BA3"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <span class="error_input" v-if="errors.password.password">{{ errors.password.password }}</span>
                    </label>
                </div>
                <div class="form__group">
                    <label for="pd-input-rep-pas" class="form__label">
                        <span>{{ $store.state.translation.profile.password.new_pass }} <span class="red">*</span></span>

                        <div class="form__input-pas-wrapper">
                            <input
                                v-model="data.password.password_confirmation"
                                @input="errors.password.password_confirmation = false" 
                                :type="hide.password_confirmation ? 'password' : 'text'"
                                class="form__input form__input--pas"/>

                            <button type="button" class="btn btn-show-password" @click="hide.password_confirmation = !hide.password_confirmation">
                                <svg width="30" height="18" viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="hide.password_confirmation">
                                    <path
                                        d="M15 0.0585938C9.26818 0.0585938 4.07027 3.19453 0.234735 8.28811C-0.0782449 8.70542 -0.0782449 9.28842 0.234735 9.70573C4.07027 14.8054 9.26818 17.9414 15 17.9414C20.7318 17.9414 25.9297 14.8054 29.7653 9.71186C30.0782 9.29455 30.0782 8.71156 29.7653 8.29425C25.9297 3.19453 20.7318 0.0585938 15 0.0585938ZM15.4112 15.2964C11.6063 15.5357 8.46425 12.3998 8.70359 8.58882C8.89997 5.44675 11.4468 2.89996 14.5888 2.70358C18.3937 2.46424 21.5357 5.60017 21.2964 9.41116C21.0939 12.5471 18.5471 15.0939 15.4112 15.2964ZM15.2209 12.3875C13.1712 12.5164 11.4774 10.8288 11.6125 8.77906C11.7168 7.08529 13.0914 5.71677 14.7852 5.60631C16.8349 5.47743 18.5287 7.16507 18.3937 9.21478C18.2832 10.9147 16.9086 12.2832 15.2209 12.3875Z"
                                        fill="#958BA3"
                                    ></path>
                                </svg>
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!hide.password_confirmation">
                                    <path
                                        d="M25.0378 4.96223C24.862 4.78648 24.6236 4.68774 24.375 4.68774C24.1264 4.68774 23.888 4.78648 23.7122 4.96223L20.7365 7.93786C18.9484 7.0642 16.9899 6.59466 15 6.56254C7.38185 6.56254 1.38841 14.101 1.13716 14.4216C1.00776 14.5867 0.937439 14.7903 0.937439 15C0.937439 15.2098 1.00776 15.4134 1.13716 15.5785C2.95061 17.7701 5.11153 19.6491 7.53372 21.1407L4.96216 23.7122C4.87262 23.7987 4.8012 23.9022 4.75207 24.0165C4.70293 24.1309 4.67707 24.2539 4.67599 24.3784C4.67491 24.5029 4.69863 24.6263 4.74577 24.7416C4.7929 24.8568 4.86252 24.9615 4.95054 25.0495C5.03856 25.1375 5.14324 25.2071 5.25845 25.2543C5.37367 25.3014 5.49712 25.3251 5.6216 25.324C5.74608 25.3229 5.8691 25.2971 5.98348 25.248C6.09786 25.1988 6.2013 25.1274 6.28779 25.0379L25.0378 6.28786C25.2135 6.11205 25.3123 5.87364 25.3123 5.62504C25.3123 5.37645 25.2135 5.13804 25.0378 4.96223ZM10.3125 15C10.314 13.7573 10.8083 12.5659 11.6871 11.6871C12.5658 10.8084 13.7572 10.314 15 10.3125C15.914 10.319 16.8053 10.5985 17.5593 11.115L11.115 17.5594C10.5984 16.8053 10.319 15.9141 10.3125 15Z"
                                        fill="#958BA3"
                                    ></path>
                                    <path
                                        d="M28.8628 14.4215C27.4602 12.722 25.8487 11.2063 24.0665 9.91028L19.6219 14.355C19.659 14.5681 19.6809 14.7837 19.6875 15C19.686 16.2427 19.1917 17.4341 18.3129 18.3129C17.4341 19.1916 16.2427 19.686 15 19.6875C14.7837 19.6809 14.5682 19.659 14.355 19.6218L11.1675 22.8093C12.4052 23.2142 13.6978 23.4261 15 23.4375C22.6181 23.4375 28.6115 15.899 28.8628 15.5784C28.9922 15.4134 29.0625 15.2097 29.0625 15C29.0625 14.7902 28.9922 14.5866 28.8628 14.4215Z"
                                        fill="#958BA3"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <span class="error_input" v-if="errors.password.password_confirmation">{{ errors.password.password_confirmation }}</span>
                    </label>
                </div>
                <button 
                    :disabled="loader_button"
                    type="submit" 
                    class="btn btn--fill-rose form__submit">
                    <preloader v-if="loader_button"/>
                    {{ $store.state.translation.profile.password.save }}
                </button>
            </form>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                loader_button: false,

                data: {
                    password: {
                        password_old: null,
                        password: null,
                        password_confirmation: null
                    }
                },
                errors: {
                    password: {
                        password_old: false,
                        password: false,
                        password_confirmation: false
                    }
                },
                hide: {
                    password_old: true,
                    password: true,
                    password_confirmation: true
                }
            }
        },
        methods: {
            updatePassword () {
                if(!this.data.password.password_old) {
                    this.errors.password.password_old = this.$store.state.translation.axios.filed_required
                    return
                } else if(this.data.password.password_old.length < 8) {
                    this.errors.password.password_old = this.$store.state.translation.profile.password.min_symbol
                    return
                }

                if(!this.data.password.password) {
                    this.errors.password.password = this.$store.state.translation.axios.filed_required
                    return
                } else if(this.data.password.password.length < 8) {
                    this.errors.password.password = this.$store.state.translation.profile.password.min_symbol
                    return
                }

                if(!this.data.password.password_confirmation) {
                    this.errors.password.password_confirmation = this.$store.state.translation.axios.filed_required
                    return
                } else if(this.data.password.password_confirmation.length < 8) {
                    this.errors.password.password_confirmation = this.$store.state.translation.profile.password.min_symbol
                    return
                }

                this.loader_button = true

                this.$axios.post('/users/update/password', JSON.stringify(this.data.password))
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':
                            this.data.password = {
                                password_old: null,
                                password: null,
                                password_confirmation: null
                            }
                            this.$toast.success(response.message)

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    this.loader_button = false

                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                        if('field' in response) {
                            this.errors.password[response.field] = response.message
                        }
                    }
                })
            }
        }
    }
</script>