<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar v-if="$store.state.user.auth"/>
                        
                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <modal-signup v-if="!$store.state.user.auth"/>
            <modal-login v-if="!$store.state.user.auth"/>
            <modal-restore v-if="!$store.state.user.auth"/>
            <modal-restore-password v-if="!$store.state.user.auth"/>

            <main>
                <cards-sidebar/>

                <section class="section section--text section--about-us">
                    <h1>{{ $store.state.translation.info.payouts_bonuses.title }}</h1>

                    <h3>{{ $store.state.translation.info.payouts_bonuses.desc1 }}</h3>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc2 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc3 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc4 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc5 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc6 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc7 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc8 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc9 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc10 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc11 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc12 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc13 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc14 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc15 }}
                    </p>
                    <ul>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc16 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc17 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc18 }}</li>
                    </ul>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc19 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc20 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc21 }}
                    </p>

                    <h3>{{ $store.state.translation.info.payouts_bonuses.desc22 }}</h3>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc23 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc24 }}
                    </p>
                    <ul>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc25 }}</li>
                        <li>
                            {{ $store.state.translation.info.payouts_bonuses.desc26 }}
                        </li>
                        <li>
                            {{ $store.state.translation.info.payouts_bonuses.desc27 }}
                        </li>
                    </ul>

                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc28 }}
                    </p>

                    <ul>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc29 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc30 }}</li>
                    </ul>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc31 }}
                    </p>

                    <h3>{{ $store.state.translation.info.payouts_bonuses.desc32 }}</h3>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc33 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc34 }} <a :href="`mailto:${$config.contacts.email}`">{{ $config.contacts.email }}</a>
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc35 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc36 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc37 }}
                    </p>

                    <ul>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc38 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc39 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc40 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc41 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc42 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc43 }}</li>
                    </ul>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc44 }}
                    </p>

                    <h3>{{ $store.state.translation.info.payouts_bonuses.desc45 }}</h3>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc46 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc47 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc48 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc49 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc50 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc51 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc52 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc53 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc54 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc55 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc56 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc57 }}
                    </p>
                    <table class="table table--text-page">
                        <thead>
                            <tr>
                                <th>{{ $store.state.translation.info.payouts_bonuses.desc58 }}</th>
                                <th>{{ $store.state.translation.info.payouts_bonuses.desc59 }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in $store.state.promo_statuses">
                                <td>{{ item.name }}</td>
                                <td>{{ item.max_withdrawal }} {{ $store.state.user.data.merchant.currency }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc60 }}
                    </p>
                    <ul>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc61 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc62 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc63 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc64 }}</li>
                        <li>
                            {{ $store.state.translation.info.payouts_bonuses.desc65 }}
                        </li>
                    </ul>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc66 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc67 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc68 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc69 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc70 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc71 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc72 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc73 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc74 }}
                    </p>

                    <h3>{{ $store.state.translation.info.payouts_bonuses.desc75 }}</h3>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc76 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc77 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc78 }}
                    </p>

                    <h3>{{ $store.state.translation.info.payouts_bonuses.desc79 }}</h3>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc80 }}
                    </p>
                    <table class="table table--text-page">
                        <thead>
                            <tr>
                                <th>{{ $store.state.translation.info.payouts_bonuses.desc58 }}</th>
                                <th>{{ $store.state.translation.info.payouts_bonuses.desc81 }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in $store.state.promo_statuses">
                                <td>{{ item.name }}</td>
                                <td>
                                    {{ $sprintf($store.state.translation.info.payouts_bonuses.desc118, (index == 0 ? '0' : $store.state.promo_statuses[index - 1].bets), $store.state.user.data.merchant.currency, item.bets, $store.state.user.data.merchant.currency) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc82 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc83 }}
                    </p>

                    <h3>{{ $store.state.translation.info.payouts_bonuses.desc84 }}</h3>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc85 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc86 }}
                    </p>

                    <ul>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc87 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc88 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc89 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc90 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc91 }}</li>
                    </ul>
                    <p>
                        {{ $sprintf($store.state.translation.info.payouts_bonuses.desc92, $config.contacts.email) }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc93 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc94 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc95 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc96 }}
                    </p>
                    <ul>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc97 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc98 }}</li>
                    </ul>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc99 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc100 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc101 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc102 }}
                    </p>
                    <p>{{ $store.state.translation.info.payouts_bonuses.desc103 }}</p>
                    <p>{{ $store.state.translation.info.payouts_bonuses.desc104 }}</p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc105 }}
                    </p>

                    <h3>{{ $store.state.translation.info.payouts_bonuses.desc106 }}</h3>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc107 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc108 }}
                    </p>
                    <ul>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc109 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc110 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc111 }}</li>
                        <li>{{ $store.state.translation.info.payouts_bonuses.desc112 }}</li>
                    </ul>

                    <h3>{{ $store.state.translation.info.payouts_bonuses.desc113 }}</h3>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc114 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc115 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc116 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.payouts_bonuses.desc117 }}
                    </p>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.info.payouts_bonuses.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.info.payouts_bonuses.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.info.payouts_bonuses.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.info.payouts_bonuses.meta.description
                    }
                ]
            }
        },
        beforeMount() {
            if(!this.$store.state.promo_statuses.length) {
                this.getVipStatuses()
            }
        }
    }
</script>
