<template>
	<div class="modal modal--signup" id="signup" ref="signup">
        <div class="modal__inner">

            <button type="button" class="btn modal__btn-close" @click="togglePopup('signup')">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 2L12 12M22 22L12 12M12 12L22 2L2 22" stroke="#5200FF" stroke-width="4" />
                </svg>
            </button>

            <div class="banner">
                <div class="banner__text">
                    <h2 class="banner_reg__title">{{ $store.state.translation.modal.bonus_register }}</h2>
                </div>

                <div class="banner__items-list">
                    <div class="banner__item" v-for="item in $store.state.promo_templates">
                        <div class="banner__item-badge">
                            {{ $sprintf($store.state.translation.modal.deposit, item.deposit_index) }}
                        </div>
                        <div class="banner__item-title">
                            <template v-if="item.type == 2">
                                <span>{{ item.freespins_number }}</span>
                                <p class="banner__item-title-side">
                                    {{ $store.state.translation.modal.freespins }}
                                </p>
                            </template>

                            <template v-if="item.type == 3">
                                <span>{{ item.percent }}</span> %
                            </template>
                        </div>

                        <p class="banner__item-text">
                            <span class="highlight">
                                {{ $sprintf($store.state.translation.modal.deposit, item.deposit_index) }} <br />
                                {{ $sprintf($store.state.translation.modal.from, item.min_deposit, $store.state.user.data.merchant.currency) }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="modal__content-wrapper">
                <h2 class="modal__title">{{ $store.state.translation.modal.register }}</h2>
                <div class="modal__content">
                    <form class="form form--login form--signup" v-on:submit.prevent="submitForm">
                        <div class="form__group">
                            <input 
                                @input="errors.email = null"
                                v-model="data.email"
                                type="email" 
                                class="form__input" 
                                :placeholder="$store.state.translation.modal.email" />
                            <span class="error_input" v-if="errors.email">{{ errors.email }}</span>
                        </div>
                        <div class="form__group">
                            <input 
                                v-model="data.password" 
                                @input="errors.password = null"
                                type="password" 
                                class="form__input" 
                                :placeholder="$store.state.translation.modal.password"
                                />
                            <span class="error_input" v-if="errors.password">{{ errors.password }}</span>
                        </div>
                        <div class="form__group">
                            <div class="select">
                                <v-select 
                                    v-model="data.currency"
                                    @input="errors.currency = null"
                                    :options="merchants"
                                    :searchable="false"
                                    :placeholder="$store.state.translation.modal.currency"
                                    class="big-vue-select provider-home"
                                    label="name">
                                    <template v-slot:no-options="{ search, searching }">
                                      <template v-if="searching">
                                         {{ $store.state.translation.select.nothing_found }}
                                      </template>
                                    </template>
                                </v-select>
                                <span class="error_input" v-if="errors.currency">{{ errors.currency }}</span>
                            </div>
                        </div>
                        <div class="form__group">
                            <button type="submit" class="btn btn--fill-rose form__btn form__btn-submit" :disabled="loader_button">
                                <preloader v-if="loader_button"/>
                                {{ $store.state.translation.modal.register }}
                            </button>
                        </div>

                        <div class="form__btn-wrapper">
                            {{ $store.state.translation.modal.already_account }}
                            <button 
                                @click="togglePopup('signup'), togglePopup('login')"
                                type="button" 
                                class="btn form__btn-forget">{{ $store.state.translation.modal.login }}!</button>
                        </div>

                        <div class="form__group">
                            <label 
                                :class="{'checkbox-error': errors.age}"
                                for="form-signup-age" 
                                class="form__label">
                                <input
                                    v-model="data.age"
                                    @input="errors.age = null"
                                    id="form-signup-age"
                                    class="form__checkbox" 
                                    type="checkbox"/>
                                <span class="form__checkmark"></span>
                                {{ $store.state.translation.modal.age }}
                            </label>
                        </div>

                        <div class="form__group">
                            <label 
                                :class="{'checkbox-error': errors.agreement}"
                                for="form-signup-accept" 
                                class="form__label">
                                <input
                                    v-model="data.agreement"
                                    @input="errors.agreement = null"
                                    id="form-signup-accept"
                                    class="form__checkbox" 
                                    type="checkbox"/>
                                <span class="form__checkmark"></span>

                                {{ $store.state.translation.modal.accept }} <router-link :to="{name: 'TermsConditions'}" target="_blank">{{ $store.state.translation.modal.terms }}</router-link>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            observer: null,
            loader_button: false,

            data: {
                email: null,
                password: null,
                currency: null,
                agreement: null,
                age: null
            },
            errors: {
                email: false,
                password: false,
                currency: false,
                agreement: false,
                age: false
            },

            merchants: []
        }
    },
    methods: {

        async submitForm () {

            if(!this.data.email) {
                this.errors.email = this.$store.state.translation.axios.filed_required
                return
            }
            if(!this.data.password) {
                this.errors.password = this.$store.state.translation.axios.filed_required
                return
            }
            if(!this.data.currency) {
                this.errors.currency = this.$store.state.translation.axios.filed_required
                return
            }
            if(!this.data.age) {
                this.$toast.error(this.$store.state.translation.modal.confirm_age)
                this.errors.age = this.$store.state.translation.axios.filed_required
                return
            }
            if(!this.data.agreement) {
                this.$toast.error(this.$store.state.translation.modal.accept_terms)
                this.errors.agreement = this.$store.state.translation.axios.filed_required
                return
            }

            this.loader_button = true

            this.$axios.post('/auth/register', JSON.stringify({
                email: this.data.email,
                password: this.data.password,
                currency: this.data.currency.id,
                ref_id: localStorage.getItem('ref_id') || null,
                click_id: localStorage.getItem('click_id') || null
            }))
            .then((response) => {
                this.loader_button = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":

                        localStorage.setItem('token', response.token)
                            
                        this.$axios.defaults.headers.common.Authorization = 'Bearer ' + response.token

                        if(localStorage.getItem('ref_id')) {
                            localStorage.removeItem('ref_id')
                        }
                        if(localStorage.getItem('click_id')) {
                            localStorage.removeItem('click_id')
                        }

                        this.setUser(response.user)

                        this.togglePopup('signup')
                        
                        this.$router.push({name: 'Home'})
                        break
                    default:
                        this.$toast.error(this.$store.state.translation.axios.unknown_error)
                }
            }).catch((error) => {
                this.loader_button = false
                let response = error.response.data
                if(response && 'status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    }
                }
            })
            
        },
        getMerchants () {
            this.$axios.post('/merchants/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.merchants.push({
                                name: item.currency,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)

                        this.appLoader('remove')
                        
                        break
                    default:
                        this.$toast.error(this.$store.state.translation.axios.unknown_error)
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if(response && 'status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        }
    },
    mounted () {
        this.observer = new MutationObserver(mutations => {
            for (let m of mutations) {
                let classList = m.target.getAttribute(m.attributeName).split(' ')
                if(classList.includes('active') && !this.merchants.length) {
                    this.appLoader('create', 80, this.$el.querySelector('.modal__inner'))
                    this.getMerchants()

                    if(!this.$store.state.promo_templates.length) {
                        this.getPromoTemplates()
                    }

                    this.observer.disconnect()
                }
            }
        })

        this.observer.observe(this.$refs.signup, {
            attributes: true,
            attributeFilter: ['class']
        })

        if(this.$refs.signup.classList.contains('active')) {
            this.appLoader('create', 80, this.$el.querySelector('.modal__inner'))
            this.getMerchants()

            if(!this.$store.state.promo_templates.length) {
                this.getPromoTemplates()
            }
        }
    }
}
</script>
