<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar/>

                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <div class="modal modal--default pfixed top__zero" id="bonus-cancel-confirm">
                <div class="modal__inner">
                    <button type="button" class="btn modal__btn-close" @click="togglePopup('bonus-cancel-confirm')">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 2L12 12M22 22L12 12M12 12L22 2L2 22" stroke="#5200FF" stroke-width="4" />
                        </svg>
                    </button>
                    <h2 class="modal__title_bonus_cancel">{{ $store.state.translation.bonus.attention }}</h2>
                    <div class="modal__content">
                        <form class="form form--default" v-on:submit.prevent="cancelPromotion">
                            <div class="form__group form__group_bonus_cancel">
                                {{ $store.state.translation.bonus.are_you_sure }}
                            </div>
                            <div class="form__group form__group_bonus_cancel_buttons">
                                <button type="submit" class="btn btn--fill-rose form__btn form__btn-submit" :disabled="history.loader_button">
                                    <preloader v-if="history.loader_button"/>
                                    {{ $store.state.translation.bonus.yes }}
                                </button>

                                <button type="button" class="btn btn--fill-rose form__btn form__btn-submit btn--outline-rose" @click="togglePopup('bonus-cancel-confirm')">
                                    {{ $store.state.translation.bonus.no }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
            <main>
                <cards-sidebar/>

                <section class="section section--profile">
                    
                    <profile-mobile/>

                    <h2 class="section__title">{{ $store.state.translation.bonus.title }}</h2>

                    <div class="section__content">
                        <!-- tabs -->
                        <div class="tabs tabs--bonus">
                            <ul class="tabs__nav">
                                <li class="tabs__nav-item">
                                    <router-link class="btn btn--fill-purple tabs__nav-btn" :class="{'active': !$props.page}" :to="{name: 'AccountBonus'}">
                                        {{ $store.state.translation.bonus.active }}
                                    </router-link>
                                </li>
                                <li class="tabs__nav-item">
                                    <router-link class="btn btn--fill-purple tabs__nav-btn" :class="{'active': $props.page == 'history'}" :to="{name: 'AccountBonus', params: {page: 'history'}}">
                                        {{ $store.state.translation.bonus.history }}
                                    </router-link>
                                </li>
                                <li class="tabs__nav-item">
                                    <router-link class="btn btn--fill-purple tabs__nav-btn" :class="{'active': $props.page == 'activate'}" :to="{name: 'AccountBonus', params: {page: 'activate'}}">
                                        {{ $store.state.translation.bonus.activate }}
                                    </router-link>
                                </li>
                            </ul>

                            <div class="tabs__content">
                                <div class="tabs__pane" :class="{'active': !$props.page}">
                                    <section class="section section--bonus section--bonus-active">

                                        <template v-if="!promotions.length">
                                            <h4 class="no__promo">{{ $store.state.translation.bonus.not_active }}</h4>
                                        </template>
                                        <template v-else>
                                            <h4 class="section__title">{{ $store.state.translation.bonus.active }}</h4>
                                        </template>

                                        <div class="section__content">

                                            <article class="card card--bonus" v-for="item in promotions">
                                                <img src="../../assets/img/cards/bonus/bonus.jpg" class="card__image" />

                                                <div class="card__content">
                                                    <h4 class="card__title">
                                                        <template v-if="item.type == 1">
                                                            {{ $sprintf($store.state.translation.bonus.type1, item.amount, $store.state.user.data.merchant.currency) }}
                                                        </template>
                                                        <template v-if="item.type == 2">
                                                            {{ $sprintf($store.state.translation.bonus.type2, item.freespins_number) }}
                                                        </template>
                                                        <template v-else-if="item.type == 3">
                                                            {{ $sprintf($store.state.translation.bonus.type3, item.percent) }}%
                                                        </template>
                                                    </h4>

                                                    <h4 class="expire__promo">
                                                        <vue-countdown-timer
                                                            :start-time="$moment(item.start_at)"
                                                            :end-time="$moment(item.expire_at)"
                                                            :interval="1000">
                                                            <template slot="countdown" slot-scope="scope">
                                                                {{ scope.props.days }}d {{ scope.props.hours }}:{{ scope.props.minutes }}:{{ scope.props.seconds }}
                                                            </template>
                                                            <template slot="end-text" slot-scope="scope">
                                                                0d 00:00:00
                                                            </template>
                                                        </vue-countdown-timer>
                                                    </h4>

                                                    <template v-if="item.type == 1">
                                                        <p class="card__text">
                                                            {{ $store.state.translation.bonus.wagering }}: <span>x{{ item.wager }}</span>
                                                        </p>

                                                        <p class="card__text">
                                                            {{ $store.state.translation.bonus.conditions }}: 

                                                            <template v-if="item.action == 2">
                                                                <span>{{ $store.state.translation.bonus.no_deposit }}</span>
                                                            </template>
                                                            <template v-if="item.action != 2">
                                                                {{ $store.state.translation.bonus.deposit }}
                                                                <span>
                                                                    {{ $sprintf($store.state.translation.bonus.condition1, item.condition.min_deposit, $store.state.user.data.merchant.currency) }}
                                                                </span>
                                                                {{ $store.state.translation.bonus.to_receive }}
                                                            </template>
                                                        </p>
                                                    </template>

                                                    <template v-else-if="item.type == 2">
                                                        <p class="card__text">
                                                            {{ $store.state.translation.bonus.number_spins }}: <span>{{ item.freespins_number }}</span>
                                                        </p>

                                                        <p class="card__text">
                                                            {{ $store.state.translation.bonus.spin_bet }}: <span>{{ item.total_bet }} {{ $store.state.user.data.merchant.currency }}</span>
                                                        </p>

                                                        <p class="card__text">
                                                            {{ $store.state.translation.bonus.wagering }}: <span>x{{ item.wager }}</span>
                                                        </p>

                                                        <p class="card__text">
                                                            {{ $store.state.translation.bonus.conditions }}: 

                                                            <template v-if="item.action == 2">
                                                                <span>{{ $store.state.translation.bonus.no_deposit }}</span>
                                                            </template>
                                                            <template v-if="item.action != 2">
                                                                {{ $store.state.translation.bonus.deposit }}
                                                                <span>
                                                                    {{ $sprintf($store.state.translation.bonus.condition1, item.condition.min_deposit, $store.state.user.data.merchant.currency) }}
                                                                </span>
                                                                {{ $store.state.translation.bonus.to_receive }}
                                                            </template>
                                                        </p>
                                                    </template>

                                                    <template v-else-if="item.type == 3">
                                                        <p class="card__text">
                                                            {{ $store.state.translation.bonus.wagering }}: <span>x{{ item.wager }}</span>
                                                        </p>

                                                        <p class="card__text">
                                                            {{ $store.state.translation.bonus.conditions }}: 

                                                            <template v-if="item.action == 2">
                                                                <span>{{ $store.state.translation.bonus.no_deposit }}</span>
                                                            </template>
                                                            <template v-if="item.action != 2">
                                                                {{ $store.state.translation.bonus.deposit }}
                                                                <span>
                                                                    {{ $sprintf($store.state.translation.bonus.condition2, item.condition.min_deposit, $store.state.user.data.merchant.currency, item.condition.max_deposit, $store.state.user.data.merchant.currency) }}
                                                                </span>
                                                                {{ $store.state.translation.bonus.to_receive }}
                                                            </template>
                                                        </p>
                                                    </template>

                                                    <footer class="card__footer">
                                                        <button 
                                                            @click="activate(item.id)"
                                                            :disabled="loader_button"
                                                            type="button" 
                                                            class="btn btn--purple card__btn">
                                                            <preloader v-if="loader_button"/>
                                                            {{ $store.state.translation.bonus.get }}
                                                        </button>
                                                    </footer>
                                                </div>
                                            </article>

                                        </div>
                                    </section>
                                </div>
                                <div class="tabs__pane" :class="{'active': $props.page == 'history'}">
                                    <section class="section section--profile section--bonus-history">
                                        <h4 class="section__title">{{ $store.state.translation.bonus.history_accrued }}</h4>

                                        <div class="section__content">
                                            <div class="table__wrapper">
                                                <table class="table table--bonus-history">
                                                    <thead>
                                                        <tr>
                                                            <th>{{ $store.state.translation.bonus.date }}:</th>
                                                            <th>{{ $store.state.translation.bonus.bonus_program }}:</th>
                                                            <th>{{ $store.state.translation.bonus.sum_bonus }}:</th>
                                                            <th>{{ $store.state.translation.bonus.wagering }}:</th>
                                                            <th>{{ $store.state.translation.bonus.wagered }}:</th>
                                                            <th>{{ $store.state.translation.bonus.status }}:</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="item in history.promotions">
                                                            <td>
                                                                {{ $moment(item.start_at).format('YYYY-MM-DD H:mm:ss') }} <br/>
                                                                {{ $moment(item.expire_at).format('YYYY-MM-DD H:mm:ss') }}
                                                            </td>
                                                            <td>
                                                                <router-link :to="{name: 'Game', params: {mode: 'real', slug: item.game_slug}}" v-if="item.type == 2">
                                                                    <img 
                                                                    :content="item.game_name"
                                                                    v-tippy="{arrow : true, arrowType: 'round'}"
                                                                    :src="item.game_image" 
                                                                    class="table__image">
                                                                </router-link>
                                                                <span>
                                                                    {{ utils.types.find(el => el.key == item.type).name }}
                                                                </span>
                                                            </td>
                                                            <td>{{ parseFloat(item.amount).toFixed(2) }} {{ $store.state.user.data.merchant.currency }}</td>
                                                            <td>x{{ item.wager }}</td>
                                                            <td>{{ parseFloat(item.wagered_amount) > parseFloat(item.to_wager_amount) ? parseFloat(item.to_wager_amount) : parseFloat(item.wagered_amount).toFixed(2) }} / {{ parseFloat(item.to_wager_amount).toFixed(2) }}</td>
                                                            <td>
                                                                <button type="button" class="btn btn--table" :class="{
                                                                    'btn--pending': item.status == 0,
                                                                    'btn--success': item.status == 1 || item.status == 2 || item.status == 4,
                                                                    'btn--error': item.status == 3 || item.status == 5
                                                                }">
                                                                    {{ utils.statuses.find(el => el.key == item.status).name }}

                                                                    <img
                                                                        v-if="[1,2].includes(item.status)"
                                                                        @click="history.cancel.promotion = item, togglePopup('bonus-cancel-confirm')"
                                                                        :content="$store.state.translation.bonus.cancel"
                                                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                                                        src="../../assets/img/icons/cancel.svg" 
                                                                        class="promocode_cancel">
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <button 
                                                    v-if="!history.nomore" 
                                                    @click="getHistory(true)"
                                                    type="button" 
                                                    class="btn btn--fill-rose section__btn more_items bonus--history-more-btn">
                                                    <preloader v-if="history.loader_button"/>
                                                    <template v-if="history.loader_button">
                                                        {{ $store.state.translation.more.loading }}
                                                    </template>
                                                    <template v-else>
                                                        {{ $store.state.translation.more.show_more }}
                                                    </template>
                                                </button>
                                            </div>

                                            <div class="acc-history acc-history--bonus">
                                                <div class="acc-history__item" v-for="item in history.promotions">
                                                    <ul>
                                                        <li>
                                                            <span class="acc-history__item-caption">{{ $store.state.translation.bonus.date }}:</span>
                                                            <span class="acc-history__item-value">
                                                                {{ $moment(item.start_at).format('YYYY-MM-DD H:mm:ss') }} <br/>
                                                                {{ $moment(item.expire_at).format('YYYY-MM-DD H:mm:ss') }}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span class="acc-history__item-caption">{{ $store.state.translation.bonus.bonus_program }}:</span>
                                                            <span class="acc-history__item-value">
                                                                <router-link :to="{name: 'Game', params: {mode: 'real', slug: item.game_slug}}" v-if="item.type == 2">
                                                                    <img 
                                                                    :content="item.game_name"
                                                                    v-tippy="{arrow : true, arrowType: 'round'}"
                                                                    :src="item.game_image" 
                                                                    class="table__image">
                                                                </router-link>
                                                                <span>
                                                                    {{ utils.types.find(el => el.key == item.type).name }}
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span class="acc-history__item-caption">{{ $store.state.translation.bonus.sum_bonus }}:</span>
                                                            <span class="acc-history__item-value">
                                                                {{ parseFloat(item.amount).toFixed(2) }} {{ $store.state.user.data.merchant.currency }}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span class="acc-history__item-caption">{{ $store.state.translation.bonus.wagering }}:</span>
                                                            <span class="acc-history__item-value">
                                                                x{{ item.wager }}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span class="acc-history__item-caption">{{ $store.state.translation.bonus.wagered }}:</span>
                                                            <span class="acc-history__item-value">
                                                                {{ parseFloat(item.wagered_amount) > parseFloat(item.to_wager_amount) ? parseFloat(item.to_wager_amount) : parseFloat(item.wagered_amount).toFixed(2) }} / {{ parseFloat(item.to_wager_amount).toFixed(2) }}
                                                            </span>
                                                        </li>

                                                        <li>
                                                            <span class="acc-history__item-caption">{{ $store.state.translation.bonus.status }}:</span>
                                                            <span class="acc-history__item-value">
                                                                <button type="button" class="btn btn--table" :class="{
                                                                    'btn--pending': item.status == 0,
                                                                    'btn--success': item.status == 1 || item.status == 2 || item.status == 4,
                                                                    'btn--error': item.status == 3 || item.status == 5
                                                                }">
                                                                    {{ utils.statuses.find(el => el.key == item.status).name }}

                                                                    <img
                                                                        v-if="[1,2].includes(item.status)"
                                                                        @click="history.cancel.promotion = item, togglePopup('bonus-cancel-confirm')"
                                                                        :content="$store.state.translation.bonus.cancel"
                                                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                                                        src="../../assets/img/icons/cancel.svg" 
                                                                        class="promocode_cancel">
                                                                </button>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <button 
                                                    v-if="!history.nomore" 
                                                    @click="getHistory(true)"
                                                    type="button" 
                                                    class="btn btn--fill-rose section__btn more_items bonus--history-more-btn">
                                                    <preloader v-if="history.loader_button"/>
                                                    <template v-if="history.loader_button">
                                                        {{ $store.state.translation.more.loading }}
                                                    </template>
                                                    <template v-else>
                                                        {{ $store.state.translation.more.show_more }}
                                                    </template>
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <div class="tabs__pane" :class="{'active': $props.page == 'activate'}">
                                    <section class="section section--profile section--bonus-activate">
                                        <h4 class="section__title">{{ $store.state.translation.bonus.promocode_activate }}</h4>

                                        <div class="section__content">
                                            <form class="form form--profile form--personal-data" v-on:submit.prevent="activatePromocode">
                                                <div class="form__group">
                                                    <label class="form__label">
                                                        <span>{{ $store.state.translation.bonus.promocode }}</span>

                                                        <div class="form__input-pas-wrapper">
                                                            <input
                                                                v-model="promocode"
                                                                @input="errors.promocode = false, promocodeUpperCase(), textPromocode()"
                                                                placeholder="WELCOME"
                                                                type="text"
                                                                class="form__input form__input--pas"/>

                                                            <button type="button" class="btn btn-promocode">
                                                                <div class="preloader-promocode-activate" v-if="loader_promocode">
                                                                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                                                </div>
                                                            </button>
                                                        </div>
                                                        <span class="error_input" v-if="errors.promocode">{{ errors.promocode }}</span>
                                                        <span class="error_input" v-if="promocode_message">{{ promocode_message }}</span>
                                                    </label>
                                                </div>

                                                <button 
                                                    :disabled="loader_button_promocode || loader_promocode || !utils.promocode"
                                                    type="submit" 
                                                    class="btn btn--fill-rose form__submit">
                                                    <preloader v-if="loader_button_promocode"/>
                                                    {{ $store.state.translation.bonus.activate }}
                                                </button>
                                            </form>
                                        </div>

                                    </section>
                                </div>
                            </div>
                        </div>
                        <!-- end of tabs -->
                    </div>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.bonus.meta_auth.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.bonus.meta_auth.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.bonus.meta_auth.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.bonus.meta_auth.description
                    }
                ]
            }
        },
        props: ['page'],
        data() {
            return {
                searchTimeout: null,
                loader_button: false,
                loader_promocode: false,
                loader_button_promocode: false,
                promotions: [],

                history: {
                    nomore: true,
                    loader_button: false,
                    cancel: {
                        balance: 0,
                        promotion: null
                    },
                    promotions: [],
                    data: {
                        limit: 10,
                        offset: 0
                    },
                },

                promocode: null,
                promocode_message: null,

                errors: {
                    promocode: false
                },

                utils: {
                    promocode: null,
                    types: [
                        {
                            name: this.$store.state.translation.bonus.types.voucher,
                            key: 1
                        },
                        {
                            name: this.$store.state.translation.bonus.types.freespins,
                            key: 2
                        },
                        {
                            name: this.$store.state.translation.bonus.types.cash,
                            key: 3
                        }
                    ],
                    statuses: [
                        {
                            name: this.$store.state.translation.bonus.statuses.pending,
                            key: 0
                        },
                        {
                            name: this.$store.state.translation.bonus.statuses.activated,
                            key: 1
                        },
                        {
                            name: this.$store.state.translation.bonus.statuses.confirmed,
                            key: 2
                        },
                        {
                            name: this.$store.state.translation.bonus.statuses.canceled,
                            key: 3
                        },
                        {
                            name: this.$store.state.translation.bonus.statuses.wagered,
                            key: 4
                        },
                        {
                            name: this.$store.state.translation.bonus.statuses.expired,
                            key: 5
                        }
                    ]
                }
            }
        },
        methods: {
            getPromotions () {
                this.appLoader('create', 100)
                this.$axios.post('/promotions/items', JSON.stringify({
                    action: null
                }))
                .then((response) => {
                    this.appLoader('remove')
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.promotions = response.promotions

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            activate(id) {
                this.loader_button = true

                this.$axios.post('/promotions/activate', JSON.stringify({
                    id: id
                }))
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            if(response.action == 'activated') {
                                this.$toast.success(response.message)
                                let index = this.promotions.findIndex(el => el.id == id)
                                this.promotions.splice(index, 1)
                                this.$router.push({name: 'AccountBonus', params: {page: 'history'}})
                            } else if(response.action == 'deposit') {
                                this.$store.state.promotion_id = id
                                this.$router.push({name: 'AccountWallet', params: {page: 'deposit'}})
                            }

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    this.loader_button = false
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            getHistory (more) {
                if(!more) {
                    this.appLoader('create', 100)
                }

                this.history.loader_button = true
                this.$axios.post('/promotions/history', JSON.stringify({
                    status: null,
                    limit: this.history.data.limit,
                    offset: this.history.data.offset
                }))
                .then((response) => {
                    if(!more) {
                        this.appLoader('remove')
                    }
                    this.history.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.history.promotions = this.history.promotions.concat(response.promotions)
                            this.history.data.offset ++
                            if(response.promotions.length < this.history.data.limit) {
                                this.history.nomore = true
                            } else {
                                this.history.nomore = false
                            }

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            cancelPromotion() {
                this.history.loader_button = true
                this.$axios.post('/promotions/cancel', JSON.stringify({
                    id: this.history.cancel.promotion.id
                }))
                .then((response) => {
                    this.history.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            let index = this.history.promotions.findIndex(el => el.id == this.history.cancel.promotion.id)
                            this.history.promotions[index].status = 3

                            this.$toast.success(response.message)

                            this.togglePopup('bonus-cancel-confirm')

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    this.history.loader_button = false
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            textPromocode () {
                clearTimeout(this.searchTimeout)

                this.searchTimeout = setTimeout(() => {
                    this.getPromocode()
                }, 1000)
            },
            getPromocode () {
                if(this.promocode) {
                    this.utils.promocode = null
                    this.promocode_message = null
                    this.loader_promocode = true
                    this.$axios.post('/promocodes/item', JSON.stringify({
                        promocode: this.promocode,
                        action: null
                    }))
                    .then((response) => {
                        this.loader_promocode = false
                        response = response.data
                        switch (response.status) {
                            case 'error':
                                this.$toast.error(response.message)
                                break
                            case 'success':

                                this.utils.promocode = response.data

                                if(this.utils.promocode && this.utils.promocode.wager > 0 && this.utils.promocode.action == 1) {
                                    this.promocode_message = this.$sprintf(this.$store.state.translation.bonus.min_dep, this.utils.promocode.min_deposit, this.$store.state.user.data.merchant.currency)
                                }

                                if(this.utils.promocode && this.utils.promocode.wager > 0 && this.utils.promocode.action == 2) {
                                    this.promocode_message = this.$sprintf(this.$store.state.translation.bonus.wager_condition, this.utils.promocode.wager)
                                }

                                this.setUser(response.user)
                                
                                break
                            default:
                                this.$toast.error(this.$store.state.translation.axios.unknown_error)
                        }
                    }).catch((error) => {
                        this.loader_promocode = false
                        let response = error.response.data
                        this.setUser(response.user)

                        if(response && 'status' in response && response.status == 'error') {
                            this.$toast.error(response.message)
                            if('field' in response) {
                                this.errors[response.field] = response.message
                            }
                        }
                    })
                }
            },
            activatePromocode () {

                if(this.utils.promocode.action == 1) {
                    this.$store.state.promocode = this.promocode
                    return this.$router.push({name: 'AccountWallet', params: {page: 'deposit'}})
                }

                //if(this.promocode) {
                    this.loader_button_promocode = true

                    this.$axios.post('/promocodes/activate', JSON.stringify({
                        promocode: this.promocode
                    }))
                    .then((response) => {
                        this.loader_button_promocode = false
                        response = response.data
                        switch (response.status) {
                            case 'error':
                                this.$toast.error(response.message)
                                break
                            case 'success':

                                this.$toast.success(response.message)

                                this.$router.push({name: 'AccountBonus', params: {page: 'history'}})

                                this.setUser(response.user)
                                
                                break
                            default:
                                this.$toast.error(this.$store.state.translation.axios.unknown_error)
                        }
                    }).catch((error) => {
                        this.loader_button_promocode = false
                        let response = error.response.data
                        this.setUser(response.user)

                        if(response && 'status' in response && response.status == 'error') {
                            this.$toast.error(response.message)
                        }
                    })
                //}
            },
            typpyHide (e) {
                this.tippy.trigger = 'mouseenter focus'
            },
            promocodeUpperCase () {
                this.promocode = this.promocode.toUpperCase()
            }
        },
        beforeMount() {
            this.getPromotions()

            if(this.$props.page == 'history') {
                this.getHistory()
            }
        },
        watch: {
            '$props.page': function(value) {
                if(value == 'history') {
                    this.getHistory()
                }
            }
        }
    }
</script>
