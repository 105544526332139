<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <modal-signup/>
            <modal-login/>
            <modal-restore/>
            <modal-restore-password/>

            <main>
                <cards-sidebar/>

                    <h2 class="section__title">{{ $store.state.translation.bonus.title }}</h2>

                    <div class="section__content">
                        <div class="tabs tabs--bonus">
                            <div class="tabs__content">
                                <section class="section section--bonus section--bonus-active">
                                    <div class="section__content">

                                        <article class="card card--bonus" v-for="item in $store.state.promo_templates">
                                            <img src="../assets/img/cards/bonus/bonus.jpg" class="card__image" />

                                            <div class="card__content">
                                                <h4 class="card__title">
                                                    <template v-if="item.type == 1">
                                                        {{ $sprintf($store.state.translation.bonus.type1, item.amount, $store.state.user.data.merchant.currency) }}
                                                    </template>
                                                    <template v-if="item.type == 2">
                                                        {{ $sprintf($store.state.translation.bonus.type2, item.freespins_number) }}
                                                    </template>
                                                    <template v-else-if="item.type == 3">
                                                        {{ $sprintf($store.state.translation.bonus.type3, item.percent) }}%
                                                    </template>
                                                </h4>
                                                <p class="card__text">
                                                    {{ $store.state.translation.bonus.deposit }}
                                                    <template v-if="item.type == 2">
                                                        <span> 
                                                            {{ $sprintf($store.state.translation.bonus.condition1, item.min_deposit, $store.state.user.data.merchant.currency) }}
                                                        </span>
                                                    </template>
                                                    <template v-else-if="item.type == 3">
                                                        <span> 
                                                            {{ $sprintf($store.state.translation.bonus.condition2, item.min_deposit, $store.state.user.data.merchant.currency, item.max_deposit, $store.state.user.data.merchant.currency) }}
                                                        </span>
                                                    </template>
                                                    {{ $store.state.translation.bonus.to_receive }}
                                                </p>

                                                <footer class="card__footer">
                                                    <button 
                                                        @click="togglePopup('login')"
                                                        type="button" 
                                                        class="btn btn--purple card__btn">
                                                        {{ $store.state.translation.bonus.get }}
                                                    </button>
                                                </footer>
                                            </div>
                                        </article>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.bonus.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.bonus.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.bonus.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.bonus.meta.description
                    }
                ]
            }
        },
        beforeMount() {
            if(!this.$store.state.promo_templates.length) {
                this.getPromoTemplates()
            }
        }
    }
</script>