<template>
	<section class="section section--profile section--deposit">
        <h4 class="section__title">{{ $store.state.translation.wallet.deposit_title }}</h4>

        <div class="section__content">
            <div class="progress_wallet">
                <div class="progress__item progress__item--paysys" :class="{'active': page >= 1}">
                    <div class="progress__item-bar"></div>
                    <span class="progress__item-text">
                        {{ $store.state.translation.wallet.deposit.step1 }}
                    </span>
                    <span class="progress__item-text-sm">
                        {{ $store.state.translation.wallet.step }} 1
                    </span>
                </div>
                <div class="progress__item progress__item--bonus" :class="{'active': page >= 2}">
                    <div class="progress__item-bar"></div>
                    <span class="progress__item-text">
                        {{ $store.state.translation.wallet.deposit.step2 }}
                    </span>
                    <span class="progress__item-text-sm">
                        {{ $store.state.translation.wallet.step }} 2
                    </span>
                </div>
                <div class="progress__item progress__item--amount" :class="{'active': page >= 3}">
                    <div class="progress__item-bar"></div>
                    <span class="progress__item-text">
                        {{ $store.state.translation.wallet.deposit.step3 }}
                    </span>
                    <span class="progress__item-text-sm">
                        {{ $store.state.translation.wallet.step }} 3
                    </span>
                </div>
            </div>

            <section class="section section--wallet-paysys" :class="{'active': page == 1}">
                <h4 class="section__title">{{ $store.state.translation.wallet.deposit.select_method }}</h4>

                <div class="section__content">
                    <div class="card card--paysys" v-for="item in paysystems" @click="page = 2, paysystem = item, getPromotions()">
                        <img :src="`${$config.ApplicationStaticURL}/paysys/${item.image}`" class="card__image" />
                    </div>
                </div>
            </section>

            <section class="section section--profile section--wallet-bonus section--bonus-active" :class="{'active': page == 2}">

                <template v-if="!promotions.length">
                    <h4 class="no__promo">{{ $store.state.translation.wallet.deposit.bonus.not_active }}</h4>
                </template>
                <template v-else>
                    <h4 class="section__title">{{ $store.state.translation.wallet.deposit.bonus.get_bonus }}</h4>
                </template>

                <h4 class="already_active_bonus" v-if="promotions_active">{{ $store.state.translation.wallet.deposit.bonus.already_active }}</h4>

                <div class="section__content" :class="{'disabled_bonus': promotions_active}">
                    <article class="card card--bonus" v-for="item in promotions" :class="{'active': promotion == item.id}">
                        <img src="../../../assets/img/cards/bonus/bonus.jpg" class="card__image" />

                        <div class="card__content">
                            <h4 class="card__title">
                                <template v-if="item.type == 1">
                                    {{ $sprintf($store.state.translation.bonus.type1, item.amount, $store.state.user.data.merchant.currency) }}
                                </template>
                                <template v-if="item.type == 2">
                                    {{ $sprintf($store.state.translation.bonus.type2, item.freespins_number) }}
                                </template>
                                <template v-else-if="item.type == 3">
                                    {{ $sprintf($store.state.translation.bonus.type3, item.percent) }}%
                                </template>
                            </h4>

                            <h4 class="expire__promo">
                                <vue-countdown-timer
                                    :start-time="$moment(item.start_at)"
                                    :end-time="$moment(item.expire_at)"
                                    :interval="1000">
                                    <template slot="countdown" slot-scope="scope">
                                        {{ scope.props.days }}d {{ scope.props.hours }}:{{ scope.props.minutes }}:{{ scope.props.seconds }}
                                    </template>
                                    <template slot="end-text" slot-scope="scope">
                                        0d 00:00:00
                                    </template>
                                </vue-countdown-timer>
                            </h4>

                            <template v-if="item.type == 1">
                                <p class="card__text">
                                    {{ $store.state.translation.bonus.wagering }}: <span>x{{ item.wager }}</span>
                                </p>

                                <p class="card__text">
                                    {{ $store.state.translation.bonus.conditions }}: 

                                    <template v-if="item.action == 2">
                                        <span>{{ $store.state.translation.bonus.no_deposit }}</span>
                                    </template>
                                    <template v-if="item.action != 2">
                                        {{ $store.state.translation.bonus.deposit }}
                                        <span>
                                            {{ $sprintf($store.state.translation.bonus.condition1, item.condition.min_deposit, $store.state.user.data.merchant.currency) }}
                                        </span>
                                        {{ $store.state.translation.bonus.to_receive }}
                                    </template>
                                </p>
                            </template>

                            <template v-else-if="item.type == 2">
                                <p class="card__text">
                                    {{ $store.state.translation.bonus.number_spins }}: <span>{{ item.freespins_number }}</span>
                                </p>

                                <p class="card__text">
                                    {{ $store.state.translation.bonus.spin_bet }}: <span>{{ item.total_bet }} {{ $store.state.user.data.merchant.currency }}</span>
                                </p>

                                <p class="card__text">
                                    {{ $store.state.translation.bonus.wagering }}: <span>x{{ item.wager }}</span>
                                </p>

                                <p class="card__text">
                                    {{ $store.state.translation.bonus.conditions }}: 

                                    <template v-if="item.action == 2">
                                        <span>{{ $store.state.translation.bonus.no_deposit }}</span>
                                    </template>
                                    <template v-if="item.action != 2">
                                        {{ $store.state.translation.bonus.deposit }}
                                        <span>
                                            {{ $sprintf($store.state.translation.bonus.condition1, item.condition.min_deposit, $store.state.user.data.merchant.currency) }}
                                        </span>
                                        {{ $store.state.translation.bonus.to_receive }}
                                    </template>
                                </p>
                            </template>

                            <template v-else-if="item.type == 3">
                                <p class="card__text">
                                    {{ $store.state.translation.bonus.wagering }}: <span>x{{ item.wager }}</span>
                                </p>

                                <p class="card__text">
                                    {{ $store.state.translation.bonus.conditions }}: 

                                    <template v-if="item.action == 2">
                                        <span>{{ $store.state.translation.bonus.no_deposit }}</span>
                                    </template>
                                    <template v-if="item.action != 2">
                                        {{ $store.state.translation.bonus.deposit }}
                                        <span>
                                            {{ $sprintf($store.state.translation.bonus.condition2, item.condition.min_deposit, $store.state.user.data.merchant.currency, item.condition.max_deposit, $store.state.user.data.merchant.currency) }}
                                        </span>
                                        {{ $store.state.translation.bonus.to_receive }}
                                    </template>
                                </p>
                            </template>

                            <footer class="card__footer">
                                <button type="button" class="btn btn--purple card__btn" @click="promotion = item.id, utils.promotion = item">
                                    <template v-if="promotion == item.id">
                                        {{ $store.state.translation.wallet.deposit.bonus.selected }}
                                    </template>
                                    <template v-else>
                                        {{ $store.state.translation.bonus.get }}
                                    </template>
                                </button>
                                <button type="button" class="btn btn--outline-white card__btn" @click="promotion = null, utils.promotion = null, page = 3">
                                    {{ $store.state.translation.wallet.deposit.bonus.not_need }}
                                </button>
                            </footer>
                        </div>
                    </article>
                </div>

                <div class="section__btns">
                    <button type="button" class="btn btn--outline-rose btn-bonus-back" @click="page = 1">
                        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.722183 9.27782C0.292606 8.84824 0.292606 8.15176 0.722183 7.72218L7.72254 0.721825C8.15212 0.292249 8.8486 0.292249 9.27817 0.721825C9.70775 1.1514 9.70775 1.84788 9.27817 2.27746L3.05564 8.5L9.27817 14.7225C9.70775 15.1521 9.70775 15.8486 9.27817 16.2782C8.8486 16.7078 8.15212 16.7078 7.72254 16.2782L0.722183 9.27782ZM21.5 9.6H1.5V7.4H21.5V9.6Z"
                                fill="#FF00E5"
                            ></path>
                        </svg>

                        {{ $store.state.translation.wallet.back }}
                    </button>
                    <button type="button" class="btn btn--fill-rose btn-bonus-next" @click="page = 3">
                        {{ $store.state.translation.wallet.next }}
                        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                fill="white"
                            ></path>
                            <path
                                d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                fill="white"
                            ></path>
                        </svg>
                    </button>
                </div>
            </section>

            <section class="section section--profile section--wallet-amount" :class="{'active': page == 3}">
                <h4 class="section__title">{{ $store.state.translation.wallet.deposit.amount }}</h4>
                <div class="section__content">
                    <p class="section__text">
                        {{ $store.state.translation.wallet.deposit.send_amount }}
                    </p>

                    <div class="form form--wallet-amount">
                        <div class="form__group">
                            <label class="form__label">
                                <span>{{ $store.state.translation.wallet.deposit.sum_deposit }}</span>
                                <div class="form__input-wrapper">
                                    <input 
                                        v-model="amount"
                                        @input="errors.amount = false"
                                        :placeholder="parseFloat(paysystem.min_payment) * 2"
                                        class="form__input"/>
                                </div>
                                <span class="error_input" v-if="errors.amount">{{ errors.amount }}</span>
                            </label>

                            <label class="form__label">
                                <span>{{ $store.state.translation.wallet.deposit.promocode }}</span>
                                <div class="form__input-pas-wrapper">
                                    <input 
                                        v-model="promocode"
                                        @input="errors.promocode = false, promocodeUpperCase()"
                                        @change="getPromocode()"
                                        placeholder="WELCOME"
                                        class="form__input form__input--pas"/>

                                    <button type="button" class="btn btn-promocode">
                                        <div class="preloader-promocode-activate" v-if="loader_promocode">
                                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                    </button>
                                </div>
                                <span class="error_input" v-if="errors.promocode">{{ errors.promocode }}</span>
                                <span class="error_input" v-if="promocode_message">{{ promocode_message }}</span>
                            </label>

                            <ul class="section__list">
                                <li v-if="utils.promotion">
                                    <span class="section__list-caption">{{ $store.state.translation.wallet.deposit.bonus_deposit }}</span>
                                    <span class="section__list-value">
                                        <template v-if="utils.promotion">
                                            <template v-if="utils.promotion.type == 1">
                                                {{ utils.promotion.amount }} {{ $store.state.user.data.merchant.currency }}
                                            </template>
                                            <template v-if="utils.promotion.type == 2">
                                                {{ $sprintf($store.state.translation.bonus.type2, utils.promotion.freespins_number) }}
                                            </template>
                                            <template v-else-if="utils.promotion.type == 3">
                                                {{ $sprintf($store.state.translation.bonus.type3, utils.promotion.percent) }}%
                                            </template>
                                        </template>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="section__btns">
                    <button type="button" class="btn btn--outline-rose btn-amount-back" @click="page = 2">
                        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.722183 9.27782C0.292606 8.84824 0.292606 8.15176 0.722183 7.72218L7.72254 0.721825C8.15212 0.292249 8.8486 0.292249 9.27817 0.721825C9.70775 1.1514 9.70775 1.84788 9.27817 2.27746L3.05564 8.5L9.27817 14.7225C9.70775 15.1521 9.70775 15.8486 9.27817 16.2782C8.8486 16.7078 8.15212 16.7078 7.72254 16.2782L0.722183 9.27782ZM21.5 9.6H1.5V7.4H21.5V9.6Z"
                                fill="#FF00E5"
                            ></path>
                        </svg>

                        {{ $store.state.translation.wallet.back }}
                    </button>
                    <button
                        @click="createPayment" 
                        :disabled="loader_button"
                        type="button" 
                        class="btn btn--fill-rose btn-amount-next">
                        <preloader v-if="loader_button"/>
                        {{ $store.state.translation.wallet.next }}
                        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                fill="white"
                            ></path>
                            <path
                                d="M21.2778 9.27782C21.7074 8.84824 21.7074 8.15176 21.2778 7.72218L14.2775 0.721825C13.8479 0.292249 13.1514 0.292249 12.7218 0.721825C12.2922 1.1514 12.2922 1.84788 12.7218 2.27746L18.9444 8.5L12.7218 14.7225C12.2922 15.1521 12.2922 15.8486 12.7218 16.2782C13.1514 16.7078 13.8479 16.7078 14.2775 16.2782L21.2778 9.27782ZM0.5 9.6H20.5V7.4H0.5V9.6Z"
                                fill="white"
                            ></path>
                        </svg>
                    </button>
                </div>
            </section>

            <section class="section section--profile section--wallet-success" :class="{'active': page == 4}">
                <h4 class="section__title">{{ $store.state.translation.wallet.deposit.success }}</h4>
                <div class="section__content">
                    <p class="section__text">
                        {{ $store.state.translation.wallet.deposit.success_deposit }}
                    </p>
                </div>

                <router-link class="btn btn--fill-rose" :to="{name: 'AccountWallet'}">{{ $store.state.translation.wallet.deposit.to_wallet }}</router-link>
            </section>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                loader_button: false,
                loader_promocode: false,
                page: 1,
                paysystems: [],
                promotions: [],
                promotions_active: 0,

                paysystem: {
                    min_payment: 0
                },

                promotion: null,
                promocode: null,
                promocode_message: null,

                amount: null,

                errors: {
                    amount: false,
                    promocode: false,
                    promotion: false
                },
                utils: {
                    types: [
                        {
                            name: this.$store.state.translation.bonus.types.voucher,
                            key: 1
                        },
                        {
                            name: this.$store.state.translation.bonus.types.freespins,
                            key: 2
                        },
                        {
                            name: this.$store.state.translation.bonus.types.cash,
                            key: 3
                        }
                    ],
                    promotion: null,
                    promocode: null
                },
            }
        },
        methods: {
            createPayment () {
                if(!this.amount) {
                    this.errors.amount = this.$store.state.translation.axios.filed_required
                    return
                }

                if(this.amount < parseFloat(this.paysystem.min_payment)) {
                    this.errors.amount = this.$sprintf(this.$store.state.translation.wallet.deposit.min_deposit, this.paysystem.min_payment, this.$store.state.user.data.merchant.currency)
                    return
                }

                this.loader_button = true

                this.$axios.post('/cashier/deposit/create', JSON.stringify({
                    paysystem: {
                        id: this.paysystem.id,
                        slug: this.paysystem.slug
                    },
                    amount: this.amount,
                    promocode: this.promocode,
                    promotion: this.promotion
                }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            if(response.data.type == 'url') {
                                window.location = response.data.url
                            }
                            
                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    this.loader_button = false
                    
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)

                        if('field' in response) {
                            this.errors[response.field] = response.message
                        }
                    }

                    if(response.promotion_invalid) {
                        this.openPopup('invalid-promo')
                    }
                })
            },
            getPaysystems () {
                this.appLoader('create', 100)
                this.$axios.post('/cashier/paysystems/items', JSON.stringify({
                    type: 'deposit'
                }))
                .then((response) => {
                    this.appLoader('remove')
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.paysystems = response.data

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            getPromotions () {
                if(this.promotions.length) {
                    return
                }
                this.appLoader('create', 100)
                this.$axios.post('/promotions/items', JSON.stringify({
                    action: 'not_nodeposit',
                    get_active: true
                }))
                .then((response) => {
                    this.appLoader('remove')
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.promotions = response.promotions
                            this.promotions_active = response.active_count

                            if(this.promotions_active) {
                                this.promotion = null
                            }

                            if(this.promotion) {
                                var item = this.promotions.find(el => el.id == this.promotion)
                                if(item) {
                                    this.utils.promotion = item
                                }
                            }

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            getPromocode (load) {
                if(this.promocode) {
                    if(load) {
                        this.appLoader('create', 100)
                    }
                    this.utils.promocode = null
                    this.promocode_message = null
                    this.loader_promocode = true
                    this.$axios.post('/promocodes/item', JSON.stringify({
                        promocode: this.promocode,
                        action: 1
                    }))
                    .then((response) => {
                        if(load) {
                            this.appLoader('remove')
                        }
                        this.loader_promocode = false
                        response = response.data
                        switch (response.status) {
                            case 'error':
                                this.$toast.error(response.message)
                                break
                            case 'success':

                                this.utils.promocode = response.data

                                if(this.utils.promocode && this.utils.promocode.wager > 0 && this.utils.promocode.action == 1) {
                                    this.promocode_message = this.$sprintf(this.$store.state.translation.bonus.min_dep, this.utils.promocode.min_deposit, this.$store.state.user.data.merchant.currency)
                                }

                                if(this.utils.promocode && this.utils.promocode.wager > 0 && this.utils.promocode.action == 2) {
                                    this.promocode_message = this.$sprintf(this.$store.state.translation.bonus.wager_condition, this.utils.promocode.wager)
                                }

                                this.setUser(response.user)
                                
                                break
                            default:
                                this.$toast.error(this.$store.state.translation.axios.unknown_error)
                        }
                    }).catch((error) => {
                        this.loader_promocode = false
                        if(load) {
                            this.appLoader('remove')
                        }
                        let response = error.response.data
                        this.setUser(response.user)

                        if(response && 'status' in response && response.status == 'error') {
                            this.$toast.error(response.message)
                            if('field' in response) {
                                this.errors[response.field] = response.message
                            }
                        }
                    })
                }
            },
            promocodeUpperCase () {
                this.promocode = this.promocode.toUpperCase()
            }
        },
        beforeMount() {
            this.getPaysystems()

            if(this.$store.state.promotion_id) {
                this.promotion = this.$store.state.promotion_id
            }
        },
        watch: {
            page: function(value) {
                if(value == 3 && this.$store.state.promocode && !this.utils.promocode) {
                    this.promocode = this.$store.state.promocode
                    this.getPromocode(true)
                }
            }
        }
    }
</script>
