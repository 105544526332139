<template>
	<section class="section section--profile section--account-history">
        <h4 class="section__title">{{ $store.state.translation.wallet.history_title }}</h4>

        <div class="section__content">
            <div class="table__wrapper">
                <table class="table table--account-history">
                    <thead>
                        <tr>
                            <th>{{ $store.state.translation.wallet.history.date }}:</th>
                            <th>{{ $store.state.translation.wallet.history.id }}:</th>
                            <th>{{ $store.state.translation.wallet.history.amount }}:</th>
                            <th>{{ $store.state.translation.wallet.history.currency }}:</th>
                            <th>{{ $store.state.translation.wallet.history.type }}:</th>
                            <th>{{ $store.state.translation.wallet.history.paysys }}:</th>
                            <th>{{ $store.state.translation.wallet.history.status }}:</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in payments">
                            <td>{{ $moment(item.created_at).format('YYYY-MM-DD H:mm:ss') }}</td>
                            <td>{{ item.uuid }}</td>
                            <td>{{ parseFloat(item.amount).toFixed(2) }}</td>
                            <td>{{ $store.state.user.data.merchant.currency }}</td>
                            <td>{{ utils.types.find(el => el.key == item.type).name }}</td>
                            <td>
                                {{ capitalizeFirstLetter(item.method) }}
                                <template v-if="item.type == 'withdrawal'">
                                   / {{ item.wallet.substring(0,10) }}<template v-if="item.wallet.length > 10">...</template>
                                </template>
                            </td>
                            <td>
                                <button type="button" class="btn btn--table btn--success" :class="{
                                    'btn--pending': item.status == 0 || item.status == 4,
                                    'btn--success': item.status == 1,
                                    'btn--error': item.status == 2 || item.status == 3
                                }">
                                    {{ utils.statuses.find(el => el.key == item.status).name }}

                                    <img
                                        v-if="item.status == 2"
                                        :content="item.reject_message"
                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                        src="../../../assets/img/icons/question-circle.svg" 
                                        class="withdraw_info">

                                    <img
                                        v-if="item.status == 0 && item.type == 'withdrawal'"
                                        @click="cancelWithdrawal(item.id)"
                                        :class="{'disabled-button': cancel_loader}"
                                        :content="$store.state.translation.wallet.history.cancel"
                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                        src="../../../assets/img/icons/cancel.svg" 
                                        class="withdraw_cancel">
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <button 
                    v-if="!nomore" 
                    @click="getPayments(true)"
                    type="button" 
                    class="btn btn--fill-rose section__btn more_items payments--history-more-btn">
                    <preloader v-if="loader_button"/>
                    <template v-if="loader_button">
                        {{ $store.state.translation.more.loading }}
                    </template>
                    <template v-else>
                        {{ $store.state.translation.more.show_more }}
                    </template>
                </button>
            </div>

            <div class="acc-history">
                <div class="acc-history__item" v-for="item in payments">
                    <ul>
                        <li>
                            <span class="acc-history__item-caption">{{ $store.state.translation.wallet.history.date }}:</span>
                            <span class="acc-history__item-value">{{ $moment(item.created_at).format('YYYY-MM-DD H:mm:ss') }}</span>
                        </li>
                        <li>
                            <span class="acc-history__item-caption">{{ $store.state.translation.wallet.history.id }}:</span>
                            <span class="acc-history__item-value">{{ item.uuid }}</span>
                        </li>
                        <li>
                            <span class="acc-history__item-caption">{{ $store.state.translation.wallet.history.amount }}:</span>
                            <span class="acc-history__item-value">{{ parseFloat(item.amount).toFixed(2) }}</span>
                        </li>
                        <li>
                            <span class="acc-history__item-caption">{{ $store.state.translation.wallet.history.currency }}:</span>
                            <span class="acc-history__item-value">{{ $store.state.user.data.merchant.currency }}</span>
                        </li>
                        <li>
                            <span class="acc-history__item-caption">{{ $store.state.translation.wallet.history.type }}:</span>
                            <span class="acc-history__item-value">{{ utils.types.find(el => el.key == item.type).name }}</span>
                        </li>
                        <li>
                            <span class="acc-history__item-caption">{{ $store.state.translation.wallet.history.paysys }}:</span>
                            <span class="acc-history__item-value">
                                {{ capitalizeFirstLetter(item.method) }}
                                <template v-if="item.type == 'withdrawal'">
                                   / {{ item.wallet.substring(0,10) }}<template v-if="item.wallet.length > 10">...</template>
                                </template>
                            </span>
                        </li>
                        <li>
                            <span class="acc-history__item-caption">{{ $store.state.translation.wallet.history.status }}:</span>
                            <span class="acc-history__item-value">
                                <button type="button" class="btn btn--table btn--success" :class="{
                                    'btn--pending': item.status == 0 || item.status == 4,
                                    'btn--success': item.status == 1,
                                    'btn--error': item.status == 2 || item.status == 3
                                }">
                                    {{ utils.statuses.find(el => el.key == item.status).name }}

                                    <img
                                        v-if="item.status == 2"
                                        :content="item.reject_message"
                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                        src="../../../assets/img/icons/question-circle.svg" 
                                        class="withdraw_info">

                                    <img
                                        v-if="item.status == 0 && item.type == 'withdrawal'"
                                        @click="cancelWithdrawal(item.id)"
                                        :class="{'disabled-button': cancel_loader}"
                                        :content="$store.state.translation.wallet.history.cancel"
                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                        src="../../../assets/img/icons/cancel.svg" 
                                        class="withdraw_cancel">
                                </button>
                            </span>
                        </li>
                    </ul>
                </div>

                <button 
                    v-if="!nomore" 
                    @click="getPayments(true)"
                    type="button" 
                    class="btn btn--fill-rose section__btn more_items payments--history-more-btn">
                    <preloader v-if="loader_button"/>
                    <template v-if="loader_button">
                        {{ $store.state.translation.more.loading }}
                    </template>
                    <template v-else>
                        {{ $store.state.translation.more.show_more }}
                    </template>
                </button>
            </div>
        </div>
    </section>
</template>


<script>
    export default {
        data() {
            return {
                nomore: true,
                loader_button: false,
                cancel_loader: false,

                payments: [],
                data: {
                    status: null,
                    type: null,
                    limit: 10,
                    offset: 0
                },
                
                utils: {
                    statuses: [
                        {
                            name: this.$store.state.translation.wallet.history.statuses.completed,
                            key: 1
                        },
                        {
                            name: this.$store.state.translation.wallet.history.statuses.pending,
                            key: 0
                        },
                        {
                            name: this.$store.state.translation.wallet.history.statuses.processing,
                            key: 4
                        },
                        {
                            name: this.$store.state.translation.wallet.history.statuses.rejected,
                            key: 2
                        },
                        {
                            name: this.$store.state.translation.wallet.history.statuses.canceled,
                            key: 3
                        }
                    ],
                    types: [
                        {
                            name: this.$store.state.translation.wallet.history.types.deposit,
                            key: 'deposit'
                        },
                        {
                            name: this.$store.state.translation.wallet.history.types.withdrawal,
                            key: 'withdrawal'
                        }
                    ]
                }
            }
        },
        methods: {
            getPayments (more) {
                if(!more) {
                    this.appLoader('create', 100)
                }

                this.loader_button = true
                this.$axios.post('/cashier/payments/history', JSON.stringify({
                    status: this.data.status ? this.data.status.key : null,
                    type: this.data.type ? this.data.type.key : null,
                    limit: this.data.limit,
                    offset: this.data.offset
                }))
                .then((response) => {
                    if(!more) {
                        this.appLoader('remove')
                    }
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.payments = this.payments.concat(response.payments)
                            this.data.offset ++
                            if(response.payments.length < this.data.limit) {
                                this.nomore = true
                            } else {
                                this.nomore = false
                            }

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            cancelWithdrawal (id) {
                this.cancel_loader = true

                this.$axios.post('/cashier/withdrawal/cancel', JSON.stringify({
                    id: id
                }))
                .then((response) => {
                    this.cancel_loader = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.$toast.success(response.message)

                            let index = this.payments.findIndex(el => el.id == id)
                            this.payments[index].status = 3

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    this.cancel_loader = false
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            capitalizeFirstLetter(str) {
              return str[0].toUpperCase() + str.slice(1)
            }
        },
        beforeMount() {
            this.getPayments()
        }
    }
</script>
