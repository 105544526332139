<template>
	<footer class="footer">
        <div class="footer__content">
            <div class="footer__col">
                <section class="section section--footer section--footer-important accordion accordion--footer">
                    <h6 class="section__title accordion__btn" @click="toggleFooterAccordion">
                        {{ $store.state.translation.footer.important_to_know }}

                        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L7 7L13 1" stroke="#FF00E5" stroke-width="2" />
                        </svg>
                    </h6>

                    <nav class="nav nav--footer accordion__pane">
                        <ul class="nav__list">
                            <li class="nav__item">
                                <router-link class="nav__link" :to="{name: 'About'}">{{ $store.state.translation.footer.about_us }}</router-link>
                            </li>
                            <li class="nav__item">
                                <router-link class="nav__link" :to="{name: 'TermsConditions'}">{{ $store.state.translation.footer.terms }}</router-link>
                            </li>
                            <li class="nav__item">
                                <router-link class="nav__link" :to="{name: 'ResponsibleGame'}">{{ $store.state.translation.footer.responsible_game }}</router-link>
                            </li>
                            <li class="nav__item">
                                <router-link class="nav__link" :to="{name: 'CookiePolicy'}">{{ $store.state.translation.footer.cookie }}</router-link>
                            </li>
                            <li class="nav__item">
                                <router-link class="nav__link" :to="{name: 'PrivacyPolicy'}">{{ $store.state.translation.footer.privacy_policy }}</router-link>
                            </li>
                            <li class="nav__item">
                                <router-link class="nav__link" :to="{name: 'AffiliateProgram'}">{{ $store.state.translation.footer.affiliate_program }}</router-link>
                            </li>
                            <li class="nav__item">
                                <router-link class="nav__link" :to="{name: 'SelfRestraint'}">{{ $store.state.translation.footer.self_restraint }}</router-link>
                            </li>
                            <li class="nav__item">
                                <router-link class="nav__link" :to="{name: 'DisputeResolution'}">{{ $store.state.translation.footer.dispute_resolution }}</router-link>
                            </li>
                            <li class="nav__item">
                                <router-link class="nav__link" :to="{name: 'PolicyKyc'}">{{ $store.state.translation.footer.policy_aml_kyc }}</router-link>
                            </li>
                            <li class="nav__item">
                                <router-link class="nav__link" :to="{name: 'HonestyGames'}">{{ $store.state.translation.footer.honesty_games }}</router-link>
                            </li>
                            <li class="nav__item">
                                <router-link class="nav__link" :to="{name: 'RegistrationPayoutsBonuses'}">{{ $store.state.translation.footer.payouts_bonuses }}</router-link>
                            </li>
                            <li class="nav__item">
                                <router-link class="nav__link" :to="{name: 'Partners'}">{{ $store.state.translation.footer.partners }}</router-link>
                            </li>
                        </ul>
                    </nav>
                </section>
            </div>

            <div class="footer__col">
                <section class="section--footer section--footer-casino">
                    <h6 class="section__title">{{ $store.state.translation.footer.casino }}</h6>
                    <nav class="nav nav--footer">
                        <ul class="nav__list">
                            <li class="nav__item">
                                <router-link class="nav__link" :to="{name: 'Home', params: {popup: 'live-dealer'}}">{{ $store.state.translation.footer.live_dealer }}</router-link>
                            </li>
                            <li class="nav__item">
                                <template v-if="$store.state.user.auth">
                                    <router-link class="nav__link" :to="{name: 'AccountVip'}">{{ $store.state.translation.footer.vip }}</router-link>
                                </template>
                                <template v-else>
                                    <router-link class="nav__link" :to="{name: 'Vip'}">{{ $store.state.translation.footer.vip }}</router-link>
                                </template>
                            </li>
                            <li class="nav__item">
                                <router-link class="nav__link" :to="{name: 'Tournaments'}">{{ $store.state.translation.footer.tournaments }}</router-link>
                            </li>
                            <li class="nav__item" v-if="1 == 2">
                                <a href="#" class="nav__link">{{ $store.state.translation.footer.aviator }}</a>
                            </li>
                        </ul>
                    </nav>
                </section>
                <section class="section--footer section--footer-help">
                    <h6 class="section__title">{{ $store.state.translation.footer.support }}</h6>
                    <address>
                        <ul>
                            <!--<li>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.7719 14.125C18.7206 14.0844 15 11.4025 13.9787 11.595C13.4912 11.6812 13.2125 12.0138 12.6531 12.6794C12.5631 12.7869 12.3469 13.0444 12.1788 13.2275C11.8252 13.1122 11.4804 12.9718 11.1469 12.8075C9.42533 11.9694 8.03437 10.5784 7.19625 8.85687C7.03179 8.52339 6.89143 8.17855 6.77625 7.825C6.96 7.65625 7.2175 7.44 7.3275 7.3475C7.99 6.79125 8.32312 6.5125 8.40938 6.02375C8.58625 5.01125 5.90625 1.265 5.87812 1.23125C5.75653 1.05754 5.59784 0.913039 5.41355 0.808189C5.22925 0.70334 5.02395 0.640768 4.8125 0.625C3.72625 0.625 0.625 4.64813 0.625 5.32562C0.625 5.365 0.681875 9.3675 5.6175 14.3881C10.6331 19.3181 14.635 19.375 14.6744 19.375C15.3525 19.375 19.375 16.2737 19.375 15.1875C19.3594 14.9768 19.2972 14.7722 19.1929 14.5884C19.0886 14.4047 18.9448 14.2464 18.7719 14.125Z"
                                        fill="#FF00E5"
                                    />
                                </svg>

                                <a :href="`tel:${$config.contacts.phone.national}`">{{ $config.contacts.phone.international }}</a>
                            </li>-->
                            <li>
                                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.5048 9.26854C10.057 9.56708 9.5368 9.7249 9.00049 9.7249C8.46421 9.7249 7.94404 9.56708 7.49622 9.26854L0.120336 4.35112C0.079414 4.32384 0.0395469 4.2954 0.000488281 4.26615V12.3239C0.000488281 13.2477 0.750195 13.9809 1.65751 13.9809H16.3434C17.2673 13.9809 18.0005 13.2312 18.0005 12.3239V4.26611C17.9613 4.29543 17.9214 4.32395 17.8804 4.35126L10.5048 9.26854Z"
                                        fill="#FF00E5"
                                    />
                                    <path
                                        d="M0.705371 3.47394L8.08126 8.39139C8.36047 8.57754 8.68046 8.6706 9.00045 8.6706C9.32048 8.6706 9.64051 8.5775 9.91972 8.39139L17.2956 3.47394C17.737 3.17986 18.0005 2.68767 18.0005 2.15646C18.0005 1.24306 17.2574 0.5 16.344 0.5H1.65695C0.743586 0.500035 0.000488281 1.2431 0.000488281 2.15734C0.000488281 2.68767 0.26402 3.17986 0.705371 3.47394Z"
                                        fill="#FF00E5"
                                    />
                                </svg>

                                <a :href="`mailto:${$config.contacts.email}`">{{ $config.contacts.email }}</a>
                            </li>
                            <li>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10 20C15.5214 20 20 15.5214 20 10C20 4.47856 15.5214 0 10 0C4.47856 0 0 4.47856 0 10C0 15.5214 4.47856 20 10 20ZM9.28569 4.28572C9.28569 3.89285 9.60713 3.57142 10 3.57142C10.3929 3.57142 10.7143 3.89285 10.7143 4.28572V9.65715L14.0179 12.3C14.325 12.5464 14.375 12.9964 14.1286 13.3036C13.9893 13.4786 13.7822 13.5714 13.5714 13.5714C13.4143 13.5714 13.2571 13.5214 13.125 13.4143L9.55359 10.5572C9.38574 10.4214 9.28575 10.2179 9.28575 10V4.28572H9.28569Z"
                                        fill="#FF00E5"
                                    />
                                </svg>
                                {{ $store.state.translation.footer.support_hours }}
                            </li>
                        </ul>
                    </address>
                </section>
            </div>

            <div class="footer__col">
                <section class="section section--footer section--footer-paysys">
                    <h6 class="section__title">{{ $store.state.translation.footer.paysystems }}</h6>
                    <div class="section__content">
                        <img src="../../assets/img/footer/paysys/1.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/paysys/2.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/paysys/3.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/paysys/4.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/paysys/5.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/paysys/6.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/paysys/7.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/paysys/8.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/paysys/9.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/paysys/10.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/paysys/11.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/paysys/12.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/paysys/13.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/paysys/14.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/paysys/15.png" alt="" class="section__image" />
                    </div>
                </section>
                <section class="section section--footer section--footer-security" v-if="1==2">
                    <h6 class="section__title">{{ $store.state.translation.footer.safety }}</h6>
                    <div class="section__content">
                        <img src="../../assets/img/footer/security/1.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/security/2.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/security/3.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/security/4.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/security/5.png" alt="" class="section__image" />
                        <img src="../../assets/img/footer/security/6.png" alt="" class="section__image" />
                    </div>
                </section>
            </div>
        </div>

        <div class="footer__copy">
            {{ $store.state.translation.footer.copy }}
        </div>

        <div class="modal modal--cookie" :class="{'active': !cookie_policy}">
            <div class="modal__inner">
                <div class="modal__content">
                    <p class="modal__text">{{ $store.state.translation.footer.cookie_req.desc }} <router-link :to="{name: 'CookiePolicy'}">{{ $store.state.translation.footer.cookie_req.policy }}</router-link></p>

                    <div class="modal__btns">
                        <button type="button" class="btn btn--outline-rose cookies-accept" @click="setCookiePolicy">{{ $store.state.translation.footer.cookie_req.accept }}</button>
                        <button type="button" class="btn btn--fill-rose cookies-decline" @click="setCookiePolicy">{{ $store.state.translation.footer.cookie_req.reject }}</button>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        data() {
            return {
                cookie_policy: false
            }
        },
        methods: {
            setCookiePolicy () {
                this.$cookies.set('cookie_policy', true)
                this.cookie_policy = true
            },
            toggleFooterAccordion () {
                document.querySelector('.accordion--footer').classList.toggle('active')
            },
        },
        beforeMount () {
            if(this.$cookies.get('cookie_policy')) {
                this.cookie_policy = true
            }
        }
    }
</script>