<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar v-if="$store.state.user.auth"/>
                        
                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <modal-signup v-if="!$store.state.user.auth"/>
            <modal-login v-if="!$store.state.user.auth"/>
            <modal-restore v-if="!$store.state.user.auth"/>
            <modal-restore-password v-if="!$store.state.user.auth"/>

            <main v-if="tournament">
                <cards-sidebar/>

                <section class="section section--single-tournament">
                    <!-- section banner -->
                    <section class="section section--banner section--banner-single-tournament">
                        <div class="banner__content">
                            <div class="banner__title">
                                {{ tournament.name }}
                            </div>
                            <span class="section__date">
                                {{ $sprintf($store.state.translation.tournament.holding, $moment(tournament.start_at).format('YYYY-MM-DD'), $moment(tournament.end_at).format('YYYY-MM-DD')) }}
                            </span>

                            <div class="section__timer">
                                <tournament-timer 
                                    :id="tournament.id" 
                                    :end_at="tournament.end_at" 
                                    @end_callback="endTournament"/>
                            </div>

                            <a class="btn btn--purple banner-btn">
                                <span class="btn__caption">{{ $store.state.translation.tournament.prize }}</span>
                                <span class="btn__value">{{ parseFloat(tournament.total_amount).toFixed(2) }} $</span>
                            </a>
                        </div>

                        <div class="section__image-wrapper">
                            <picture>
                                <img
                                    :src="`${$config.ApplicationStaticURL}/tournaments/${tournament.image_sm}`"
                                    alt=""
                                    class="section__image_small"
                                />

                                <img
                                    :src="`${$config.ApplicationStaticURL}/tournaments/${tournament.image}`"
                                    alt=""
                                    class="section__image"
                                />
                            </picture>
                        </div>
                    </section>
                    <!-- end of section banner -->

                    <div class="section__content">
                        <template v-if="tournament.position && tournament.status == 0">
                            <button 
                                disabled
                                type="button" 
                                class="btn btn--fill-rose section__btn">
                                    {{ $store.state.translation.tournament.is_participant }}
                            </button>
                        </template>
                        <template v-else-if="!tournament.position && tournament.status == 0">
                            <button 
                                @click="participate"
                                :disabled="loader_button"
                                type="button" 
                                class="btn btn--fill-rose section__btn">
                                    <preloader v-if="loader_button"/>
                                    {{ $store.state.translation.tournament.participate2 }}
                            </button>
                        </template>
                        <template v-else-if="tournament.status == 1">
                            <button 
                                disabled
                                type="button" 
                                class="btn btn--fill-rose section__btn">
                                    {{ $store.state.translation.tournament.ended }}
                            </button>
                        </template>
                    </div>
                </section>


                <section class="section" :class="{'section--tournament-participants': tournament.status == 0, 'section--tournament-winners': tournament.status == 1}">

                    <div class="tournament__header">
                        <h3 class="section__title">
                            <template v-if="tournament.status == 0">
                                {{ $store.state.translation.tournament.top_users }}
                            </template>
                            <template v-else-if="tournament.status == 1">
                                {{ $store.state.translation.tournament.winners2 }}
                            </template>
                        </h3>

                        <h3 class="tournament__position" v-if="tournament.position && ('auth' in this.$store.state.user) && this.$store.state.user.auth">
                            {{ $store.state.translation.tournament.position }}: {{ tournament.position }}
                        </h3>
                    </div>

                    <div class="section__content">
                        <div class="table__wrapper">
                            <table class="table" :class="{'table--participants': tournament.status == 0, 'table--winners': tournament.status == 1}">
                                <thead>
                                    <tr>
                                        <th>{{ $store.state.translation.tournament.place }}</th>
                                        <th>
                                            <template v-if="tournament.status == 0">
                                                {{ $store.state.translation.tournament.user }}
                                            </template>
                                            <template v-else-if="tournament.status == 1">
                                                {{ $store.state.translation.tournament.winner }}
                                            </template>
                                        </th>
                                        <template v-if="tournament.status == 0">
                                            <th>
                                                <template v-if="tournament.type == 1">
                                                    {{ $store.state.translation.tournament.exp }}
                                                </template>
                                                <template v-else-if="tournament.type == 2">
                                                    {{ $store.state.translation.tournament.coefficient }}
                                                </template>
                                            </th>
                                        </template>
                                        <th v-if="tournament.status == 1">{{ $store.state.translation.tournament.winning }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="tournament.status == 0">
                                        <tr v-for="(item, index) in tournament.participants">
                                            <td>
                                                <div class="table__cell-inner">
                                                    {{ index + 1 }} {{ $store.state.translation.tournament.place }}
                                                </div>
                                            </td>
                                            <td>
                                                <div class="table__cell-inner">
                                                    <img :src="`${$config.ApplicationStaticURL}/avatar/${item.avatar}`" class="table__image" />
                                                    {{ item.username }}
                                                </div>
                                            </td>
                                            <template v-if="tournament.type == 1">
                                                <td>{{ (parseFloat(item.coefficient) * 10).toFixed(2) }}</td>
                                            </template>
                                            <template v-else-if="tournament.type == 2">
                                                <td class="table__cell-inner participant-game">
                                                    <template v-if="item.game_name">
                                                        <img 
                                                            :content="item.game_name" 
                                                            v-tippy="{arrow : true, arrowType: 'round'}"
                                                            :src="item.game_image"
                                                            class="table__image" />
                                                    </template>
                                                    x{{ parseFloat(item.coefficient).toFixed(2) }}
                                                </td>
                                            </template>
                                        </tr>
                                    </template>
                                    <template v-else-if="tournament.status == 1">
                                        <tr v-for="(item, index) in tournament.winners">
                                            <td>
                                                <div class="table__cell-inner">
                                                    {{ index + 1 }} {{ $store.state.translation.tournament.place }}
                                                </div>
                                            </td>
                                            <td>
                                                <div class="table__cell-inner">
                                                    <img :src="`${$config.ApplicationStaticURL}/avatar/${item.avatar}`" class="table__image" />
                                                    {{ item.username }}
                                                </div>
                                            </td>
                                            <td>{{ parseFloat(item.amount).toFixed(2) }} USD</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="acc-history" :class="{'acc-history--participants': tournament.status == 0, 'acc-history--winners': tournament.status == 1}">
                            <template v-if="tournament.status == 0">
                                <div class="acc-history__item" v-for="(item, index) in tournament.participants">
                                    <ul>
                                        <li>
                                            <span class="acc-history__item-caption">{{ $store.state.translation.tournament.place }}</span>
                                            <span class="acc-history__item-value">
                                                {{ index + 1 }} {{ $store.state.translation.tournament.place }}
                                            </span>
                                        </li>
                                        <li>
                                            <span class="acc-history__item-caption">{{ $store.state.translation.tournament.user }}</span>
                                            <span class="acc-history__item-value">
                                                <img :src="`${$config.ApplicationStaticURL}/avatar/${item.avatar}`" class="table__image"/>
                                                {{ item.username }}
                                            </span>
                                        </li>
                                        <li>
                                            <!--
                                            <span class="acc-history__item-caption">
                                                <template v-if="tournament.type == 1">
                                                    {{ $store.state.translation.tournament.exp }}
                                                </template>
                                                <template v-else-if="tournament.type == 2">
                                                    {{ $store.state.translation.tournament.coefficient }}
                                                </template>
                                            </span>
                                            -->
                                            <span class="acc-history__item-value">

                                                <template v-if="tournament.type == 1">
                                                    {{ (parseFloat(item.coefficient) * 10).toFixed(2) }}
                                                </template>
                                                <template v-else-if="tournament.type == 2">
                                                    <template v-if="item.game_name">
                                                        <img 
                                                            :content="item.game_name" 
                                                            v-tippy="{arrow : true, arrowType: 'round'}"
                                                            :src="item.game_image"
                                                            class="table__image" />
                                                    </template>
                                                    x{{ parseFloat(item.coefficient).toFixed(2) }}
                                                </template>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                            <template v-else-if="tournament.status == 1">
                                <div class="acc-history__item" v-for="(item, index) in tournament.winners">
                                    <ul>
                                        <li>
                                            <span class="acc-history__item-caption">{{ $store.state.translation.tournament.place }}</span>
                                            <span class="acc-history__item-value">
                                                {{ index + 1 }} {{ $store.state.translation.tournament.place }}
                                            </span>
                                        </li>
                                        <li>
                                            <span class="acc-history__item-caption">{{ $store.state.translation.tournament.winner }}</span>
                                            <span class="acc-history__item-value">
                                                <img :src="`${$config.ApplicationStaticURL}/avatar/${item.avatar}`" class="table__image"/>
                                                {{ item.username }}
                                            </span>
                                        </li>
                                        <li>
                                            <span class="acc-history__item-caption">{{ $store.state.translation.tournament.winning }}</span>
                                            <span class="acc-history__item-value">
                                                {{ parseFloat(item.amount).toFixed(2) }} USD
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </div>
                    </div>
                </section>


                <section class="section section--tournament-rules">
                    <h3 class="section__title">{{ $store.state.translation.tournament.terms }}</h3>

                    <div class="section__content">
                        <ul>
                            <li>
                                {{ $store.state.translation.tournament.about }}
                            </li>
                            <li>
                                {{ $store.state.translation.tournament.exp_awarded }}
                            </li>
                            <li>
                                <template v-if="tournament.type == 1">
                                    {{ $store.state.translation.tournament.type1 }}
                                </template>
                                <template v-else-if="tournament.type == 2">
                                    {{ $store.state.translation.tournament.type2 }}
                                </template>
                            </li>
                            <li>
                                <template v-if="tournament.type == 1">
                                    {{ $store.state.translation.tournament.position1 }}
                                </template>
                                <template v-else-if="tournament.type == 2">
                                    {{ $store.state.translation.tournament.position2 }}
                                </template>
                            </li>
                            <li>
                                {{ $sprintf($store.state.translation.tournament.min_bet, parseFloat(tournament.min_bet), $store.state.user.data.merchant.currency) }}
                            </li>
                            <li>
                                {{ $sprintf($store.state.translation.tournament.min_win, parseFloat(tournament.min_win), $store.state.user.data.merchant.currency) }}
                            </li>
                            <li>
                                {{ $store.state.translation.tournament.usd_to_xp }}
                            </li>
                            <li>
                                {{ $store.state.translation.tournament.desc1 }}
                            </li>
                            <li>
                                {{ $store.state.translation.tournament.desc2 }}
                            </li>
                            <li>
                                {{ $store.state.translation.tournament.desc3 }}
                            </li>
                            <li>
                                {{ $store.state.translation.tournament.desc4 }}
                            </li>
                            <li>
                                {{ $store.state.translation.tournament.desc5 }}
                            </li>
                            <li>
                                {{ $store.state.translation.tournament.desc6 }}
                            </li>
                            <li>
                                {{ $store.state.translation.tournament.desc7 }}
                            </li>
                            <li>
                                {{ $store.state.translation.tournament.desc8 }}
                            </li>
                            <li>
                                {{ $store.state.translation.tournament.desc9 }}
                            </li>
                            <li>
                                {{ $store.state.translation.tournament.desc10 }}
                            </li>
                            <li>
                                {{ $store.state.translation.tournament.desc11 }}
                            </li>
                        </ul>

                        <div class="section__text">
                            <p>
                                {{ $store.state.translation.tournament.desc }}
                            </p>
                        </div>
                    </div>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.tournament.meta2.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.tournament.meta2.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.tournament.meta2.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.tournament.meta2.description
                    }
                ]
            }
        },
        props: ['id'],
        data() {
            return {
                loader_button: false,
                tournament: null
            }
        },
        methods: {
            getTournament () {
                this.appLoader('create', 100)
                this.$axios.post(`/tournaments/item/${this.$props.id}`)
                .then((response) => {
                    this.appLoader('remove')
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.tournament = response.tournament

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            participate () {
                if (!('auth' in this.$store.state.user) || !this.$store.state.user.auth) {
                    this.togglePopup('login')
                    return
                }
                this.loader_button = true
                this.$axios.post(`/tournaments/participate/${this.$props.id}`)
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.$toast.success(response.message)

                            this.tournament.position = String(0)

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    this.loader_button = false
                    let response = error.response.data
                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            endTournament (id) {
                if(this.tournament.status == 0) {
                    this.getTournament()
                }
            }
        },
        beforeMount () {
            this.getTournament()
        }
    }
</script>
