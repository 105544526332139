<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar v-if="$store.state.user.auth"/>
                        
                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <modal-signup v-if="!$store.state.user.auth"/>
            <modal-login v-if="!$store.state.user.auth"/>
            <modal-restore v-if="!$store.state.user.auth"/>
            <modal-restore-password v-if="!$store.state.user.auth"/>

            <main>
                <cards-sidebar/>

                <section class="section section--text section--about-us">
                <h1>{{ $store.state.translation.info.about.title }}</h1>

                <p>
                    {{ $store.state.translation.info.about.desc }}
                </p>

                <h4>{{ $store.state.translation.info.about.contacts }}</h4>
                
                <p>
                    E-mail: <a :href="`mailto:${$config.contacts.email}`">{{ $config.contacts.email }}</a>
                </p>
                <p>
                    Telegram: <a :href="`https://t.me/${$config.contacts.telegram}`" target="_blank">{{ $config.contacts.telegram }}</a>
                </p>
            </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.info.about.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.info.about.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.info.about.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.info.about.meta.description
                    }
                ]
            }
        }
    }
</script>
