import Vue from 'vue'
import router from './router'
import http from './events/Http'
import store from './events/Store'
import config from './events/Config'

import App from './App.vue'

import VueTippy, { TippyComponent } from 'vue-tippy'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Moment from 'moment-timezone'
import 'moment/locale/ru'
import VueCountdownTimer from 'vuejs-countdown-timer'
import VueMask from 'v-mask'
import VueMeta from 'vue-meta'
import VueCookies from 'vue-cookies'
//import VueAnalytics from 'vue-analytics'

Vue.config.productionTip = false

Vue.prototype.$config = config
Vue.prototype.$axios = http
Vue.prototype.$sprintf = require('sprintf-js').sprintf

Vue.use(VueTippy)
Vue.use(VueToast, {
    position: 'top-right',
    duration: 3000
})
Vue.use(VueReCaptcha, {
  siteKey: config.RecaptchaV3.token
})
Vue.use(VueCountdownTimer)
Vue.use(VueMask)
Vue.use(VueMeta)
Vue.use(VueCookies, {
    expires: '1y'
})
/*Vue.use(VueAnalytics, {
    id: 'G-NX42V1PBKZ',
    //disableScriptLoader: true,
    autoTracking: {
        exception: true,
        exceptionLogs: false
    },
    router
})*/


Moment.tz.setDefault(config.Timezone)
Moment.locale('ru')
Vue.prototype.$moment = Moment

Vue.component('tippy', TippyComponent)
Vue.component('v-select', vSelect)


Vue.mixin({
    methods: {
        setUser (data) {
            store.commit('setUser', data)
        },
        setLang (value) {
            store.commit('setLang', value)
        },
        setLanguages (value) {
            store.commit('setLanguages', value)
        },
        setTranslation (value) {
            store.commit('setTranslation', value)
        },
        async recaptchaV3() {
            await this.$recaptchaLoaded()
            return await this.$recaptcha(config.RecaptchaV3.action)
        },
        async getVipStatuses () {
            await this.$axios.post('/users/status/items')
            .then((response) => {
                  response = response.data
                  switch (response.status) {
                      case 'error':
                          break
                      case 'success':
                          store.commit('setPromoStatuses', response.data)
                          break
                      default:
                      //Error
                  }
            })
        },
        getPromoTemplates () {
            this.$axios.post('/promotions/templates/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        break
                    case 'success':
                        store.commit('setPromoTemplates', response.data)
                        break
                    default:
                    //Error
                }
            })
        },
        togglePopup(popup_id) {
            document.querySelector('#' + popup_id).classList.toggle('active')
            document.querySelector('body').classList.toggle('overflow_hidden')
        },
        appLoader(type, blackout, target = null) {
            if(type == 'create') {
                let bg_loader = document.createElement('div')
                bg_loader.classList.add('bg-loader')

                if(!target) {
                    bg_loader.classList.add('height_100vh')
                }

                bg_loader.style.background = `rgb(0 0 0 / ${blackout}%)`

                let preloader = document.createElement('div')
                preloader.classList.add('preloader')

                let lds_ring = document.createElement('div')
                lds_ring.classList.add('lds-ring')

                for (let i = 4; i > 0; i--) {
                    let div = document.createElement('div')
                    lds_ring.appendChild(div)
                }

                preloader.appendChild(lds_ring)
                bg_loader.appendChild(preloader)

                if(!target) {
                    target = document.querySelector('#app')
                }

                target.prepend(bg_loader)

                document.body.classList.add('bg_overflow_hidden')
                document.body.classList.add('pfixed')
            } else if(type == 'remove') {
                document.querySelector('.bg-loader').remove()
                document.body.classList.remove('bg_overflow_hidden')
                document.body.classList.remove('pfixed')
            }
        }
    }
})

new Vue({
    el: '#app',
    router,
    store,
    computed: {
        auth () {
            return {
                auth: store.state.user.auth,
                status: store.state.user.data.status
            }
        }
    },
    watch: {
        auth (user, old) {
            var route = this.$router.currentRoute

            if(route.meta.auth == 'auth' && user.status != 'active') {
                this.$axios.defaults.headers.common.Authorization = null
                router.push({name: 'Home'})
            }
          
            if (route.meta.auth == 'auth' && !user.auth) {
                this.$axios.defaults.headers.common.Authorization = null
                router.push({name: 'Home'})
            } else if (route.meta.auth == 'guest' && user.auth) {
                router.push({name: 'Home'})
            }
        }
    },
    render: (h) => h(App)
})
