<template>
    <div class="container">
        <div class="wrapper">
            
            <header-main/>

            <aside class="sidebar">
                <div class="sidebar__inner">
                    <div class="sidebar__inner-scroll">
                        <profile-sidebar v-if="$store.state.user.auth"/>
                        
                        <cards-sidebar/>
                    </div>
                </div>
            </aside>

            <modal-signup v-if="!$store.state.user.auth"/>
            <modal-login v-if="!$store.state.user.auth"/>
            <modal-restore v-if="!$store.state.user.auth"/>
            <modal-restore-password v-if="!$store.state.user.auth"/>

            <main>
                <cards-sidebar/>

                <section class="section section--text section--about-us">
                    <h1>{{ $store.state.translation.info.self_restraint.title }}</h1>

                    <p>
                        {{ $store.state.translation.info.self_restraint.desc1 }}
                    </p>
                    <ul>
                        <li>{{ $store.state.translation.info.self_restraint.desc2 }}</li>
                        <li>{{ $store.state.translation.info.self_restraint.desc3 }}</li>
                        <li>{{ $store.state.translation.info.self_restraint.desc4 }}</li>
                    </ul>
                    <h3>{{ $store.state.translation.info.self_restraint.desc5 }}</h3>

                    <p>
                        {{ $store.state.translation.info.self_restraint.desc6 }}
                    </p>

                    <h3>{{ $store.state.translation.info.self_restraint.title }}</h3>
                    <p>
                        {{ $store.state.translation.info.self_restraint.desc7 }}
                    </p>
                </section>

            </main>
            
            <footer-main/>

        </div>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.info.self_restraint.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.info.self_restraint.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.info.self_restraint.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.info.self_restraint.meta.description
                    }
                ]
            }
        }
    }
</script>